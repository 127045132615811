<template>
  <div class="full-screen opened">
    <div class="full-screen-header config-screen-header">
      <div class="full-screen-backstep cur-pointer" @click="store.stepBack" v-if="store.step > 1 && !store.finish">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M31.6666 20H8.33331" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 31.6673L8.33331 20.0007L20 8.33398" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <span class="full-screen-logo" style="cursor: pointer" @click="gotoMain">Terminal A</span>

      <div class="full-screen-closer r90deg" @click="closeMe">
        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
          <rect width="39" height="39" rx="19.5" fill="black"/>
          <line x1="11.6895" y1="10.8086" x2="29.3672" y2="28.4862" stroke="#F7F7F7"/>
          <line x1="10.9863" y1="28.4882" x2="28.664" y2="10.8106" stroke="#F7F7F7"/>
        </svg>
      </div>
    </div>
    <div class="full-screen-body config-wrapper">
      <div class="car-configurator" :class="store.step > 1 && store.step < 6 ? 'cut' : ''">
        <Transition>
          <config-step v-if="store.step < 6"/>
        </Transition>

        <Transition mode="out-in" v-if="store.step < 6">
          <component :is="activeComponent"></component>
        </Transition>
        <frm v-if="store.step === 6"/>
      </div>
      <config-footer v-if="store.step > 1 && store.step < 6" />
    </div>

  </div>

</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  // import car from '@/stuff/configurator/lixiang/l7.js'
  import carData from '@/stuff/configurator/data.js'
  import configStep from "@/components/configurator/step.vue"
  import configFooter from "@/components/configurator/footer.vue"
  import equipments from '@/components/configurator/equipments.vue'
  import colors from "@/components/configurator/colors.vue"
  import wheels from "@/components/configurator/wheels.vue"
  import salons from "@/components/configurator/salon.vue"
  import review from '@/components/configurator/review.vue'
  import frm from '@/components/configurator/form.vue'
  export default {
    props:['brand','model'],
    components: {equipments,configStep,colors,configFooter,wheels,salons,review,frm},
    data: ()=> {
      return {
        store: useConfiguratorStore(),
      }
    },
    created() {

      const car = carData[this.brand][this.model]
      this.store.car = car
      document.body.classList.add("no-scroll");

    },
    methods: {
      gotoMain() {
        window.location.href = '/'
      },
      closeMe() {
        document.body.classList.remove("no-scroll");
        window.cconfig.unmount()
      }
    },
    computed: {
      activeComponent() {
        if (this.store.step === 5) {
          return 'review'
        } else if (this.store.step === 4) {
          return 'salons'
        } else if (this.store.step === 3) {
          return 'wheels'
        } else if (this.store.step === 2) {
          return 'colors'
        } else {
          return 'equipments'
        }
      }
    }
  }
</script>

<style>
  .car-configurator {
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F7F7F7;
    height: 100%;
    overflow-y: auto;
    scrollbar-color: rgba(0, 0, 0, 0.5) #ffffff !important;
    scrollbar-width: thin !important;
  }

  .config-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  /* .car-configurator.cut {
    height: calc(100vh - 220px);
  } */

  .next-btn {
    display: flex;
    width: 181px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #000;
    background: #000;
    color: #FFFFFF;
    height: 44px;
    font-size: 16px;
  }

  .next-btn:hover {
    background: #FFFFFF;
    color: #000000;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .full-screen-backstep {
    cursor: pointer;
    font-size: 16px;
    display: flex;
    gap: 17px;
    align-items: center;
    position: absolute;
    top: 21px;
    left: 50px;
    transition: all 0.5s ease-out;
  }

  @media (max-width: 1280px) {
    .car-configurator {
      padding: 40px 0 0 0;
    }
  }

  @media (max-width: 1000px) {
    .car-configurator {
      padding: 30px 0 0 0;
    }
  }

  @media (max-width: 500px) {
    .car-configurator {
      padding: 20px;
    }

    .config-wrapper {
      padding: 0;
    }
    .full-screen-backstep {
      top: 21px;
      left: 20px;

    }

    .config-screen-header {
      justify-content: center;
    }
  }

</style>