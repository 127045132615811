import { defineStore } from 'pinia'

export const useDealerAuthStore = defineStore('catalog', {
    state: () => {
        return {
            country:'',
            screen: 'login'
        }
    },
    getters: {

    },
    actions: {
        switchScreen(screen) {
          this.screen = screen
          window.scrollTo(0, 0);
        },
    }
})