<template>
  <div class="login-container">
    <screen-one v-if="store.screen === 'login'" />
    <screen-two v-if="store.screen === 'country'" />
    <screen-three v-if="store.screen === 'acc'" />
    <screen-four v-if="store.screen === 'done'" />
  </div>
</template>

<script>
  import {useDealerAuthStore} from "@/stores/dealer_auth.js";
  import screenOne from "@/components/login_dealers/screen_1.vue";
  import screenTwo from "@/components/login_dealers/screen_2.vue";
  import screenThree from "@/components/login_dealers/screen_3.vue";
  import screenFour from "@/components/login_dealers/screen_4.vue";


  export default {
    props: ['screen'],
    components: {screenOne,screenTwo,screenThree,screenFour},
    data: ()=> {
      return {
        store: useDealerAuthStore(),
        loading: false
      }
    },
    created() {
      if (this.screen !== undefined) {
        this.store.screen = this.screen;
      }
    },
    methods: {

    }
  }
</script>

<style lang="scss">

  .dealers-subtitle2 {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    width: 100%;
    font-size: 30px;
    margin-bottom: 80px;
  }

  .login-container {
    padding: 80px 0 100px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-container h1 {
    font-size: 80px;
    text-align: center;
    margin-bottom: 0;
  }

  .frm-wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px 0 50px 0;
    width: 500px;
  }


  .login-btn {
    margin: 20px 0 60px 0;
    font-size: 16px;
    width: 165px;
  }

  .pwd-viewer {
    position: absolute;
    right: 8px;
    bottom: 22px;
    cursor: pointer;
  }

  .with_error {
    border-bottom: 1px solid #E94040!important;
  }

  @media (max-width: 1460px) {
    .frm-wrapper {
      padding: 40px 0 40px 0;
    }

    .frm-wrapper .login-btn {
      margin: 10px 0 20px 0;
    }

  }


  @media (max-width: 1280px) {
    .login-container {
      padding: 60px 0;
    }

    .frm-wrapper {
      padding: 0 0 40px 0;
    }

    .dealers-subtitle2 {
      margin-bottom: 60px;
    }
  }

    @media (max-width: 630px) {
      .login-container h1 {
        font-size: 42px;
      }
      .frm-wrapper {
        width: 100%;
        padding: 40px 20px;
      }
    }


  .acc-link {
    color: #000;
    margin-top: 10px;
  }


  .acc-wrapper {
    padding: 40px 0;
  }

  .acc-fields {
    display: flex;
    gap: 60px;
    margin-bottom: 60px;
  }

    .acc-fields input {
      width: 325px;
    }

  .checkbox-button {
    margin-bottom: 40px;
  }

  .dealers-step {
    margin-bottom: 60px;
    color: #000;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }


    @media (max-width: 1280px) {

      .acc-wrapper {
        padding: 0 0;
      }


      .dealers-step {
        margin-bottom: 40px;
      }

      .acc-fields {
        gap: 20px;
      }
      .acc-fields input {
        width: 280px;
      }

      .login-container h1 {
        font-size: 62px!important;
        display: block;
        padding: 0 20px;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 900px) {
      .acc-fields {
        flex-direction: column;
        gap: 40px;
      }
      .acc-fields input {
        width: 420px;
      }
    }

    @media (max-width: 550px) {
      .login-container h1 {
        font-size: 42px!important;
      }
      .acc-wrapper {
        width: 100%;
        padding: 80px 20px;
      }
      .acc-fields input {
        width: 100%;
      }
    }

  .done-wrapper {
    padding: 150px 0;
  }

  .done-wrapper h1 {
    margin-bottom: 60px;
  }

  .done-wrapper span {
    color: rgba(0, 0, 0, 0.50);
    font-size: 22px;
  }

</style>

