<template>
  <div class="store-select">
    <div class="current-option" @click="toggle" :class="opened ? 'opened' : ''">
      <span class="current-title">
        {{currentTitle}}
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
        <path d="M12 4.37404C12 4.27916 11.9597 4.18205 11.8815 4.10923C11.7251 3.96359 11.4692 3.96359 11.3129 4.10923L5.96031 9.09415L0.685951 4.18205C0.529569 4.03641 0.27367 4.03641 0.117288 4.18205C-0.0390949 4.32769 -0.0390949 4.56602 0.117288 4.71166L5.67598 9.89077C5.83236 10.0364 6.08826 10.0364 6.24464 9.89077L11.8815 4.64104C11.9621 4.56602 12 4.47114 12 4.37404Z" fill="black"/>
      </svg>
    </div>
    <div class="select-options" v-show="opened">
      <div class="store-option" v-for="(item, index) in options" :key="index" @click="selectOption(item)">
        {{item.title}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: () => []
      }
    },
    data: ()=> {
      return {
        opened: false,
        placeholder: ''
      }
    },
    methods: {
      toggle() {
        this.opened = !this.opened
      },
      close() {
        this.opened = false
      },
      selectOption(item) {

      }
    },
    computed: {
      currentTitle(){
        if (this.value === '') {
          return this.options[0]?.title
        } else {
          return this.options.find( d => d.val === this.value)?.title
        }
      }
    }
  }
</script>

<style>
  .store-select {
    position: relative;
    cursor: pointer;
    font-size: 12px;
  }

  .rotated {
    transform: rotate(180deg);
  }

  .current-option {
    padding: 14px;
    background: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }

  .current-option.opened {
    border-radius: 10px 10px 0 0;
  }

  .select-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    border-radius: 0 0 10px 10px;
  }

  .store-option {
    user-select: none;
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid #E5E5E5;
    background: #F8F8F8;
  }

  .store-option:hover {
    background: #E9EAEC;
  }

  .store-option:first-child {
    border-top: 1px solid #E5E5E5;
  }

  .store-option:last-child {
    border-radius: 0 0 10px 10px;
    border-bottom: 1px solid #F8F8F8;
  }

</style>