// [{:name=>"L6", :id=>111}, {:name=>"L7", :id=>112}, {:name=>"L8", :id=>113}, {:name=>"L9", :id=>114}, {:name=>"MEGA", :id=>115}]

import l6 from '@/stuff/configurator/lixiang/l6.js'
import l7 from '@/stuff/configurator/lixiang/l7.js'
import l8 from '@/stuff/configurator/lixiang/l8.js'
import l9 from '@/stuff/configurator/lixiang/l9.js'
import mega from '@/stuff/configurator/lixiang/mega.js'

const data = {111:l6,112:l7,113:l8,114:l9,115:mega}

export { data as default };