<template>
  <div class="pressa-slider">

    <div class="logo-slider splide" ref="logoslider">
      <div class="splide__track">
        <div class="splide__list">
          <div class="splide__slide logo-slide" v-for="(quote,index) in quotes">
            <img :src="quote.logo" @click="switchSlide(index)">
          </div>
        </div>
      </div>
    </div>

    <div class="sliders-divider"></div>

    <div class="press-slider splide" ref="slider">

      <div class="splide__arrows press-arrows">
        <button class="splide__arrow splide__arrow--prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
            <path d="M9.37659 0C9.53474 0 9.69658 0.067134 9.81795 0.197453C10.0607 0.458091 10.0607 0.884589 9.81795 1.14523L1.50975 10.0661L9.69658 18.8567C9.93932 19.1174 9.93932 19.5439 9.69658 19.8045C9.45384 20.0652 9.05664 20.0652 8.8139 19.8045L0.182052 10.54C-0.0606841 10.2794 -0.0606841 9.8529 0.182052 9.59226L8.93159 0.197453C9.05664 0.0631849 9.21477 0 9.37659 0Z" fill="black" fill-opacity="0.5"/>
          </svg>
        </button>
        <button class="splide__arrow splide__arrow--next">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
            <path d="M0.623409 0C0.465262 0 0.30342 0.067134 0.182052 0.197453C-0.0606842 0.458091 -0.0606842 0.884589 0.182052 1.14523L8.49025 10.0661L0.30342 18.8567C0.0606833 19.1174 0.0606833 19.5439 0.30342 19.8045C0.546156 20.0652 0.943361 20.0652 1.1861 19.8045L9.81795 10.54C10.0607 10.2794 10.0607 9.8529 9.81795 9.59226L1.06841 0.197453C0.943362 0.0631849 0.785233 0 0.623409 0Z" fill="black" fill-opacity="0.5"/>
          </svg>
        </button>
      </div>

      <div class="splide__track">
        <div class="splide__list">
          <div class="splide__slide pressa-slide" v-for="quote in quotes">
            <h3>
              <span v-html="quote.title"></span>
            </h3>

            <a class="c-black arrow-link" :href="quote.url" target="_blank">
              <span style="padding:0">Подробнее</span>
              <img alt="" class="shevron-connect" :src="'/img/shevron-right.svg'" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '@splidejs/splide/css/core';
  import Splide from '@splidejs/splide';
export default {
  components: {},
  data: ()=> {
    return {
      splide: null,
      splide_index: 0,
      splide_logo_index: 0,
      splide_logo: null,
      config:{},
      quotes: [
        {title:"«Открылся первый автомобильный консигнационный склад. Управлением складом занимается российский оператор Terminal&nbsp;A»",
          url:"https://iz.ru/1829693/2025-01-28/v-belorussii-otkryli-novyi-sklad-dlia-postavki-avto-bmw-i-toyota-v-rf",
          logo:"/img/izvestia.png"},
        {title:"«Партнёрство Сбера и&nbsp;международного импортера Terminal&nbsp;A сделает процесс доставки авто из-за рубежа проще и&nbsp;безопаснее»",
          url:"https://www.sberbank.ru/ru/sberpress/all/article?newsID=0753ec20-48b8-4bc6-b1de-94f61ca94187&blockID=1303&regionID=77&lang=ru&type=NEWS",
          logo:"/img/sber_color.png"},
        {title:"«В&nbsp;России состоялась первая покупка автомобиля из&nbsp;Китая с&nbsp;использованием аккредитива. Операторами сделки выступили Сбер и Terminal&nbsp;A»",
          url:"https://www.autostat.ru/finance/59244/",
          logo:"/img/avtostat.png"},
        {title:"«Terminal A has become the operator of&nbsp;the largest automotive consignment warehouse for Russian customers»",
          url:"https://interfax.com/newsroom/top-stories/109442/",
          logo:"/img/interfax.png"}
      ],
      current_index: 0
    }
  },
  mounted() {
    const _this = this
    this.splide = new Splide(this.$refs.slider,{
      perPage: 1,
      arrows: false,
      pagination: false,
      breakpoints: {
        600: {
          arrows: true,
        }
      }
    }).mount();

    this.splide.on( 'move', function (newIndex, prevIndex, destIndex) {
      _this.switchSlide(destIndex);
    } );

    this.splide_logo = new Splide(this.$refs.logoslider,{
      perPage  : 5,
      perMove  : 1,
      focus    : 'center',
      drag     : false,
      trimSpace: false,
      arrows: false,
      pagination: false,
      breakpoints: {
        1150: {
          perPage  : 3,
        },
        600: {
          perPage  : 1
        }
      }
    }).mount();

    // this.splide = new Splide(this.$refs.slider,{
    //   type: 'loop',
    //   perPage: 1,
    //   arrows: false,
    //   pagination: false,
    //   wheel: true,
    //   releaseWheel: true,
    //   padding: { left: 60, right: 60 },
    //   breakpoints: {
    //     500: {
    //       padding: { left: 10, right: 10 },
    //       arrows:true,
    //       perPage: 1
    //     }
    //   }
    // }).mount();
  },
  methods: {
    switchSlide(index) {
      console.log(index)
      this.splide.go(index)
      this.splide_logo.go(index)
    }
  }

}
</script>

<style>
  @media (max-width: 600px) {
    #v-pressa2 {
      padding: 0 15px;
    }
  }

</style>

<style scoped>
  .pressa-slider {
    border-radius: 10px;
    background: #F8F8F8;
    padding: 150px 60px 200px 60px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-bottom: 200px;
  }

  .press-slider .press-arrows {
    display: none;
  }

  .sliders-divider {
    width: 323px;
    height: 1px;
    background: rgba(0, 0, 0, 0.60);
    margin: 0 auto;
  }

  h3 {
    text-align: center;
    margin-bottom: 60px;
  }

  .arrow-link {
    font-size: 30px;
    text-decoration: none;
    margin: 0 auto;
    display: flex;
  }

  .logo-slide {
    display: flex;
    justify-content: center;
    user-select: none;
  }

  .logo-slide img {
    height: 50px;
    filter: grayscale(100%);
    cursor: pointer;
  }

  .logo-slider .is-active img {
    filter: grayscale(0);
  }

  .splide__arrow {
    border: none;
    background: transparent;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    z-index: 1;
  }

  @media (max-width: 1700px) {
    .sliders-divider {
      width: 223px;
    }
  }

  @media (max-width: 1400px) {
    .pressa-slider {
      padding: 100px 60px 100px 60px;
      gap: 40px;
      margin-bottom: 150px;
    }
    .logo-slide img {
      height: 38px;
    }

    .sliders-divider {
      width: 150px;
    }
    .arrow-link {
      font-size: 22px;
    }
  }

  @media (max-width: 1000px) {
    .pressa-slider {
      padding: 80px 40px 80px 40px;
    }
  }

  @media (max-width: 700px) {
    .logo-slide img {
      height: 28px;
    }
  }

  @media (max-width: 600px) {
    .logo-slide img {
      height: 35px;
    }
    .pressa-slider {
      padding: 60px 20px 60px 20px;
      margin-bottom: 100px;
    }
    .arrow-link {
      font-size: 20px;
    }
    .sliders-divider {
      display: none;
    }
    .press-slider .press-arrows {
      display: block;
    }
    .splide__arrow--prev {
      position: absolute;
      left:-50px;
    }

    .splide__arrow--next {
      position: absolute;
      right:-50px;
    }

  }

</style>