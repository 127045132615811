function closeCallbackPopup(node) {
    const done = node.querySelector(".callback-frm-done");
    const frm = node.querySelector(".callback-frm");
    document.body.classList.remove("no-scroll");
    done.classList.add("hidden");
    frm.classList.remove("hidden");
    node.classList.remove("opened");
}

function openCallbackPopup(node) {
    node.classList.add("opened")
    document.body.classList.add("no-scroll");
}

function initPopupControl(node) {
    const closer = node.querySelector('.full-screen-closer');
    closer.addEventListener('click', () => {
        closeCallbackPopup(node);
    })

    document.querySelectorAll('.popup-opener').forEach(opener => {
        opener.addEventListener("click", () => {
            openCallbackPopup(node)
        });
    })
}


const init = function () {
    const callback_popup = document.querySelector('#callback-popup');
    if (callback_popup) {
        initPopupControl(callback_popup);
        if (window.location.href.includes('welcome=yes')) {
            openCallbackPopup(callback_popup);
        }
    }
}



const Callback = {
    init: init
}

export { Callback as default };
