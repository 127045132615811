<template>
  <div class="config-step-wrapper">
    <div class="config-step">
      Шаг {{store.step}} из 5
    </div>
    <div class="config-step-title">
      {{title}}
    </div>
  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore()
      }
    },
    computed: {
      title() {
        if (this.store.step === 2) {
          return 'Выберите цвет автомобиля'
        } else if (this.store.step === 3) {
          return 'Выберите диски'
        } else if (this.store.step === 4) {
          return 'Выберите цвет салона'
        } else if (this.store.step === 5) {
          return 'Ваш Lixiang'
        } else {
          return 'Выберите комплектацию'
        }
      }
    }
  }
</script>

<style>
  .config-step {
    margin-bottom: 40px;
  }

  .config-step-title {
    margin-bottom: 60px;
    font-size: 63px;
    color: #000000;
    letter-spacing: -2.52px;
  }

  @media (max-width: 1280px) {
    .config-step-title {
      font-size: 50px;
      margin-bottom: 40px;
      letter-spacing: unset;
    }
    .config-step {
      margin-bottom: 30px;

    }
  }

  @media (max-width: 1000px) {

  }

  @media (max-width: 500px) {
    .config-step-title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .config-step {
      margin-bottom: 10px;
    }
  }
</style>