<template>
  <div class="cc-footer">
    <div class="cc-footer-title">
      <span class="capitalize">{{store.car.brand}}</span> {{store.car.model}} {{store.currentCar.title}}
    </div>
    <div class="cc-footer-price">
      <div class="ccf-price">
        <span>Общая стоимость</span>
        <h5>{{ Intl.NumberFormat('ru-RU', {style: 'currency',currency: 'RUB',minimumFractionDigits: 0}).format(this.store.totalPrice)}}</h5>
      </div>
      <button class="next-btn" @click="store.nextStep">
        {{nextText}}
      </button>
    </div>
  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore()
      }
    },
    computed: {
      nextText() {
        if (this.store.step === 5) {
          return 'Оставить заявку'
        } else {
          return 'Дальше'
        }
      }
    }
  }

</script>

<style>
  .cc-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.40);
    width: 100%;
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cc-footer-title {
    font-size: 30px;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .cc-footer-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 720px;
    padding: 15px 0 15px 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.40);
  }

  .ccf-price {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .ccf-price span {
    color: rgba(0, 0, 0, 0.60);
  }

  .ccf-price h5 {
    color: #000000;
    font-size: 30px;
  }

  @media (max-width: 1500px) {
    .cc-footer-price {
      width: 50%;
    }
  }

  @media (max-width: 1280px) {
    .cc-footer {
      width: 100%;
      padding: 10px 40px;
    }

    .cc-footer-title {
      font-size: 24px;
    }

    .cc-footer-price {
      padding: 0  0 0 20px;
      width: 500px;
    }

    .ccf-price h5 {
      font-size: 20px;
    }

    .ccf-price span {
      font-size: 14px;
    }
  }

  @media (max-width: 1000px) {
    .cc-footer-price {
      width: 50%;
    }
  }

  @media (max-width: 750px) {
    .cc-footer {
      flex-direction: column;
      align-items: flex-start;
      gap: 11px;
      padding: 0;
      margin-bottom: 20px;
    }

    .cc-footer-title {
      padding: 20px 20px 0 20px;
    }

    .cc-footer-price {
      border: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      width: 100%;
      padding: 0 20px;
    }

    .ccf-price {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .next-btn {
      width: 100%!important;
    }

  }
</style>