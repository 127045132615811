<template>
  <div class="dealers-step">
    2 из 2
  </div>
  <h1>Выбирайте новый тип продаж.</h1>
  <div class="dealers-subtitle2">Заполните три простых поля.</div>
  <div class="acc-wrapper">
    <div class="acc-fields">
      <div class="input-block fl">
        <input class="fl-input" type="text" v-model="inn" :placeholder="`Введите ${store.country === 'ru' ? 'ИНН' : 'УНП'}`" :maxlength="store.country === 'ru' ? 12 : 9" @keydown="isNumber($event)" :class="errors.includes('некорректный ИНН') ? 'with_error' : ''" />
        <label class="fl-label">Введите {{store.country === 'ru' ? 'ИНН' : 'УНП'}}</label>
      </div>

      <div class="input-block fl">
        <input class="fl-input" type="email" v-model="email" placeholder="Введите почту" :class="errors.includes('некорректный email') ? 'with_error' : ''" />
        <label class="fl-label">Введите почту</label>
      </div>

      <div class="input-block fl">
        <imask-input v-model:typed="phone" :mask="store.country === 'ru' ? '+7 (000) 000-00-00' : '+375 00 000-00-00'" placeholder="Введите телефон" class="phone-input fl-input" :class="errors.includes('некорректный телефон') ? 'with_error' : ''" />
        <label class="fl-label">Введите телефон</label>
      </div>
    </div>

    <label class="checkbox-button">
      <input type="checkbox" class="checkbox-button__input" v-model="agree">
      <span class="checkbox-button__control"></span>
      <span style="color: rgba(0, 0, 0, 0.70);" class="checkbox-button__label">Я согласен с <a style="color: rgba(0, 0, 0, 0.70);" href="/privacy" target="_blank">политикой конфиденциальности</a></span>
    </label>

    <div class="errors" v-if="errors.length > 0">
      <p class="c-red capitalize">Ошибка: {{errors.join(', ')}}</p>
    </div>

    <p v-if="loading">подождите ...</p>
    <div v-if="!loading" style="display: flex; gap:10px;">
      <button class="black-btn btn-h-white" @click="send()" >Отправить</button>
      <button style="background: white;color:black" class="black-btn" @click="store.switchScreen('country')">Назад</button>
    </div>
  </div>
</template>

<script>
  import {useDealerAuthStore} from "@/stores/dealer_auth.js";
  import { IMaskComponent } from 'vue-imask';

  export default {
    components: {'imask-input': IMaskComponent},
    data: ()=> {
      return {
        store: useDealerAuthStore(),
        step: 1,
        email:'',
        inn: '',
        phone: '',
        agree: false,
        loading: false,
        errors: [],
      }
    },
    methods: {
      isNumber: (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      validate() {
        this.errors = []
        if (this.inn.trim().length !== 12 && this.inn.trim().length !== 10 && this.store.country === 'ru') {
          this.errors.push('некорректный ИНН')
        }
        if (this.inn.trim().length !== 9 && this.store.country === 'bel') {
          this.errors.push('некорректный УНП')
        }
         if (this.phone.trim().length !== 10 && this.store.country === 'ru') {
          this.errors.push('некорректный телефон')
        }
        if (this.phone.trim().length !== 9 && this.store.country === 'bel') {
          this.errors.push('некорректный телефон')
        }
        const re = /\S+@\S+\.\S+/;
        if (!re.test(this.email)) {
          this.errors.push('некорректный email')
        }
        if (!this.agree) {
          this.errors.push('несогласие с политикой конфиденциальности')
        }
      },
      send() {
        this.validate()
        if (this.errors.length === 0) {
          const _this = this
          this.loading = true
          fetch('/log_dealer', {
            headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
            method: 'POST',
            body: JSON.stringify({phone:this.phone,email:this.email,inn:this.inn,country:this.store.country})
          })
              .then(response => response.json())
              .then(data => {
                _this.loading = false
                _this.store.switchScreen('done')
              })
        }
      }
  }
  }
</script>

<style scoped>

</style>