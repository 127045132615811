<template>
  <h1>Вход</h1>
  <div class="frm-wrapper">
    <div class="input-block fl">
      <imask-input v-model:typed="phone" :mask="'(000) 000-00-00'" placeholder='(900) 000-00-00' class="fl-input" />
      <label class="fl-label">Телефон</label>
    </div>
    <div class="input-block fl">
      <input class="fl-input" :type="pwd_type" v-model="pwd" @focus="clearPwd" placeholder="Пароль" :class="login_errors.includes('некорректный пароль') || login_errors.includes('Логин или пароль введен неверно. Если Вы не аккредитованы, нажмите “Аккредитоваться”.') ? 'with_error' : ''" />
      <label class="fl-label">Пароль</label>
      <span class="pwd-viewer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" @click="switchPwdType">
            <path d="M8.0013 3.08105C6.37334 3.08185 4.77903 3.54446 3.40352 4.41516C2.028 5.28587 0.927722 6.52893 0.230469 8C0.927082 9.47153 2.02722 10.715 3.40287 11.5858C4.77853 12.4566 6.37317 12.9189 8.0013 12.9189C9.62937 12.9189 11.224 12.4566 12.5996 11.5858C13.9753 10.715 15.0754 9.47153 15.7721 8C15.0748 6.52893 13.9746 5.28587 12.599 4.41516C11.2235 3.54446 9.62924 3.08185 8.0013 3.08105ZM8.0013 11.5855C6.73664 11.5801 5.49501 11.2469 4.39755 10.6185C3.30009 9.99013 2.3843 9.08793 1.73954 8C2.38018 6.9084 3.29507 6.00323 4.39345 5.37427C5.49184 4.74533 6.73557 4.41445 8.0013 4.41445C9.26697 4.41445 10.5107 4.74533 11.6091 5.37427C12.7075 6.00323 13.6224 6.9084 14.263 8C13.6182 9.08793 12.7024 9.99013 11.605 10.6185C10.5075 11.2469 9.2659 11.5801 8.0013 11.5855ZM8.0013 5.55719C7.5181 5.55719 7.04584 5.70045 6.64412 5.96887C6.24241 6.23729 5.9293 6.61881 5.74442 7.0652C5.55953 7.51153 5.51115 8.00267 5.60541 8.47653C5.69966 8.9504 5.93232 9.38567 6.27395 9.72733C6.61558 10.0689 7.05084 10.3016 7.5247 10.3959C7.99857 10.4901 8.4897 10.4417 8.9361 10.2569C9.38244 10.0719 9.76397 9.75887 10.0324 9.35713C10.3008 8.9554 10.4441 8.48313 10.4441 8C10.4441 7.35213 10.1867 6.7308 9.72857 6.27267C9.2705 5.81455 8.64917 5.55719 8.0013 5.55719ZM8.0013 9.10947C7.78184 9.10947 7.5673 9.0444 7.3849 8.92247C7.20244 8.80053 7.06024 8.62727 6.97624 8.42453C6.8923 8.22187 6.8703 7.99873 6.9131 7.78353C6.9559 7.56833 7.06157 7.37067 7.21677 7.21547C7.3719 7.06033 7.56964 6.95467 7.78484 6.91187C8.00004 6.869 8.2231 6.891 8.42584 6.975C8.62857 7.05893 8.80184 7.20113 8.92377 7.3836C9.04564 7.56607 9.11077 7.78053 9.11077 8C9.11037 8.29413 8.99344 8.57613 8.78544 8.78413C8.57744 8.99213 8.29544 9.10913 8.0013 9.10947Z" fill="black"/>
          </svg>
        </span>
    </div>
    <div class="errors" v-if="login_errors.length > 0">
      <p class="c-red capitalize">{{login_errors.join(', ')}}</p>
    </div>
    <div class="text-center">
      <p v-if="loading">подождите ...</p>
      <button v-if="!loading" class="black-btn btn-h-white login-btn" @click="login()">Войти</button>
    </div>


    <div class="text-center w-100">
      Для получения доступа пройдите аккредитацию дилера
    </div>
    <div class="text-center w-100">
      <a class="acc-link" href="javascript:void(0)" @click="store.switchScreen('country')">Аккредитоваться</a>
    </div>
  </div>
</template>

<script>
  import {useDealerAuthStore} from "@/stores/dealer_auth.js";
  import { IMaskComponent } from 'vue-imask';
  import login from '@/components/login/index.vue'
  export default {
    components: {'imask-input': IMaskComponent,login},
    data: ()=> {
      return {
        store: useDealerAuthStore(),
        login_errors: [],
        phone: '',
        pwd: '',
        loading: false,
        pwd_type: 'password'
      }
    },
    methods: {
      validate() {
        this.login_errors = []

        if (this.phone.length < 10) {
          this.login_errors.push('некорректный телефон')
        }
        if (this.pwd.trim().length === 0) {
          this.login_errors.push('некорректный пароль')
        }
      },
      switchPwdType() {
        if (this.pwd_type === 'password') {
          this.pwd_type = 'text';
        }  else {
          this.pwd_type = 'password';
        }
      },
      login() {
        this.validate()
        if (this.login_errors.length === 0) {
          const _this = this
          this.loading = true
          fetch('/log_dealer', {
            headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
            method: 'POST',
            body: JSON.stringify({phone:this.phone,password:this.pwd})
          })
              .then(response => response.json())
              .then(data => {
                _this.loading = false
                if (data.login === undefined) {
                  _this.login_errors.push('Логин или пароль введен неверно. Если Вы не аккредитованы, нажмите “Аккредитоваться”.')
                } else {
                  window.location.href = "/store/cars";
                }

              })
        }
      }
    }
  }
</script>

<style scoped>

</style>