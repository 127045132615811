<template>
  <div class="buy-new-slider splide" ref="slider">
    <div class="splide__arrows buy-arrows">
      <button class="splide__arrow splide__arrow--prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
          <path d="M9.37659 0C9.53474 0 9.69658 0.067134 9.81795 0.197453C10.0607 0.458091 10.0607 0.884589 9.81795 1.14523L1.50975 10.0661L9.69658 18.8567C9.93932 19.1174 9.93932 19.5439 9.69658 19.8045C9.45384 20.0652 9.05664 20.0652 8.8139 19.8045L0.182052 10.54C-0.0606841 10.2794 -0.0606841 9.8529 0.182052 9.59226L8.93159 0.197453C9.05664 0.0631849 9.21477 0 9.37659 0Z" fill="black" fill-opacity="0.5"/>
        </svg>
      </button>
      <button class="splide__arrow splide__arrow--next">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
          <path d="M0.623409 0C0.465262 0 0.30342 0.067134 0.182052 0.197453C-0.0606842 0.458091 -0.0606842 0.884589 0.182052 1.14523L8.49025 10.0661L0.30342 18.8567C0.0606833 19.1174 0.0606833 19.5439 0.30342 19.8045C0.546156 20.0652 0.943361 20.0652 1.1861 19.8045L9.81795 10.54C10.0607 10.2794 10.0607 9.8529 9.81795 9.59226L1.06841 0.197453C0.943362 0.0631849 0.785233 0 0.623409 0Z" fill="black" fill-opacity="0.5"/>
        </svg>
      </button>
    </div>

    <div class="splide__track">
      <div class="splide__list">
        <div class="splide__slide buy-slide" v-for="(item,index) in content">
          <img :src="`/img/buy_new/${index}.webp?v=2`" />
          <span class="buy-slide-title">{{item.title}}</span>
          <span class="buy-slide-desc">{{item.description}}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import '@splidejs/splide/css/core';
  import Splide from '@splidejs/splide';
  export default {
    props: ['content'],
    data: ()=> {
      return {

      }
    },
    mounted() {
      new Splide(this.$refs.slider,{
        // fixedWidth: true,
        gap: 20,
        arrows: false,
        pagination: false,
        wheel: true,
        releaseWheel: true,
        padding: { left: 60, right: 60 },
        breakpoints: {
          500: {
            padding: { left: 10, right: 10 },
            arrows:true,
            perPage: 1
          }
        }
      }).mount();
    }
  }
</script>

<style scoped>

  .buy-new-slider {
    position: relative;
  }

  .buy-slide {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .buy-slide img {
    width: 100%;
  }

  .buy-slide-title {
    font-size: 22px;
    line-height: 29px;
  }

  .buy-slide-desc {
    color: rgba(0, 0, 0, 0.50);
    line-height: 22px;
  }

  .buy-arrows {
    display: none;
  }

  .splide__arrow {
    border: none;
    background: transparent;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    z-index: 1;
  }

  @media (max-width: 700px) {
    .buy-slide-title {
      font-size: 20px;
      line-height: 26px;
    }
  }

  @media (max-width: 500px) {
    .buy-slide {
      width: 100%;
      padding: 0 25px;
      gap: 10px;
    }

    .buy-arrows {
      display: block;
    }

    .splide__arrow--prev {
      position: absolute;
      left:0;
    }

    .splide__arrow--next {
      position: absolute;
      right:0;
    }

  }



</style>