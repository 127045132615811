<template>
  <div class="car-config-equipments">
    <div class="cc-equipment" v-for="(car,index) in store.car.equipments" @click="store.selectCar(index)">
      <img :src="car.img" class="w-100" :alt="car.title" />
      <div class="cc-description">
        <div class="cce-title">
          {{car.title}}
        </div>
        <div class="cce-price">
          {{ Intl.NumberFormat('ru-RU', {style: 'currency',currency: 'RUB',minimumFractionDigits: 0}).format(car.price) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore()
      }
    }
  }

</script>

<style>
  .car-config-equipments {
    display: flex;
    gap: 20px;
  }

  .cc-equipment {
    width: 380px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    user-select: none;
    transition: all 0.4s ease-out;
  }

  .cc-equipment img {
    pointer-events: none;
  }

  .cc-equipment:hover {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid rgba(0, 0, 0, 0.20);
  }

  .cc-description {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    align-items: center;
  }

  .cce-title {
    font-size: 24px;
  }

  .cce-price {
    font-size: 16px;
  }

  @media (max-width: 1280px) {
    .cc-equipment {
      width: 265px;
    }
  }

  @media (max-width: 870px) {
    .car-config-equipments {
      flex-direction: column;
      max-width: 100%;
      margin-bottom: 40px;
    }

    .cc-equipment {
      width: 380px;
      max-width: 100%;
    }

  }

</style>