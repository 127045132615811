const car = {
    brand: 'lixiang',
    model: 'L8',
    brand_id: 13,
    model_id: 113,
    mirrored_photos: true,
    equipments: [
        {title:'Pro',price:6500000,img:'/configurator/13/113/L8_pro.jpg',slug:'pro',default_color_id:1,default_wheel_id:1},
        {title:'Max',price:7500000,img:'/configurator/13/113/L8_max.jpg',slug:'max',default_color_id:2,default_wheel_id:1},
        {title:'Ultra',price:8500000,img:'/configurator/13/113/L8_ultra.jpg',slug:'ultra',default_color_id:6,default_wheel_id:3,wheels:[3,4]}
    ],
    colors: [
        {id:1,title:'Gold metallic',img:'/configurator/13/113/colors/1.png',price:0},
        {id:2,title:'Silver Metallic',img:'/configurator/13/113/colors/2.png',price:0},
        {id:3,title:'Grey Metallic',img:'/configurator/13/113/colors/3.png',price:0},
        {id:4,title:'Black metallic',img:'/configurator/13/113/colors/4.png',price:0},
        {id:5,title:'Green Special Edition Pearl',img:'/configurator/13/113/colors/5.png',price:122000},
        {id:6,title:'Baby Blue Special Edition',img:'/configurator/13/113/colors/6.png',price:122000},
    ],
    wheels: [
        {id:1,title:'20-inch silver-gray two-tone',img:'/configurator/13/113/wheels/1.png',price:0},
        {id:2,title:'20-inch black and gray two-tone',img:'/configurator/13/113/wheels/2.png',price:0},
        {id:3,title:'21-inch silver-gray two-tone',img:'/configurator/13/113/wheels/3.png',price:122000},
        {id:4,title:'21-inch black and gray two-tone',img:'/configurator/13/113/wheels/4.png',price:122000}
    ],
    salons: [
        {id:1,title:'Black and white',img:'/configurator/13/113/colors_salon/1.png',price:0},
        {id:2,title:'Black and orange',img:'/configurator/13/113/colors_salon/2.png',price:0},
        {id:3,title:'Black sports',img:'/configurator/13/113/colors_salon/3.png',price:0},
    ]
}

export { car as default };