<template>
  <div class="callback-frm">
    <h2 class="callback-header c-black" v-if="!store.finish">
      Связаться с менеджером.
      <br />
      <span class="c-grey">Получить предложение.</span>
    </h2>
    <div class="text-center" v-if="!store.finish">
      <form id="callback-form" @submit.prevent="send">
        <div class="fl input-block">
          <input ref="maska" class="phone-mask fl-input" name="contact" placeholder="" required="" type="text" v-model="phone">
          <label class="fl-label">Введите номер телефона</label>
        </div>
        <div class="checkbox-button" v-if="phone.length > 17">
          Нажимая кнопку «Отправить», вы подтверждаете, что&nbsp;согласны с <a class="c-black" href="/privacy" target="_blank">политикой конфиденциальности</a>, даете поручение и <a class="c-black" href="/personal" target="_blank">согласие</a> на обработку персональных данных.
        </div>
<!--        <label class="checkbox-button">-->
<!--          <input class="checkbox-button__input" type="checkbox" v-model="agree">-->
<!--          <span class="checkbox-button__control"></span>-->
<!--          <span class="checkbox-button__label" style="color: rgba(0, 0, 0, 0.70);"> Я согласен с  <a href="/privacy" style="color: rgba(0, 0, 0, 0.70);" target="_blank">политикой конфиденциальности</a></span>-->
<!--        </label>-->
        <input v-if="phone.length > 17 && agree" type="submit" name="commit" value="Отправить" id="callback-btn" class="black-btn btn-h-white" data-disable-with="...">
      </form>
    </div>
    <h2 class="callback-header c-black" v-if="store.finish">
      Готово.
    </h2>
    <span class="c-black" v-if="store.finish">Мы сделаем кое-какие дела и свяжемся с Вами.</span>
  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  import IMask from 'imask';
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore(),
        phone:'',
        agree: true
      }
    },
    mounted() {
      IMask(this.$refs.maska, {
        mask: '+{7} (000) 000-00-00',
        prepare: (appended, masked) => {
          if (appended === '8' && masked.value === '') {
            return '';
          } else if (appended[0] === '8' && appended.length > 10) {
            // console.log(appended)
            return appended.substring(1)
          }
          return appended;
        }
      });
    },
    methods: {
      send() {
        const data = {phone: this.phone, comment: this.store.carDescription}
        fetch('/car_msg', {
          headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
          method: 'POST',
          body: JSON.stringify(data)
        })
        this.store.finish = true
      }
    }
  }
</script>

<style>

</style>