<template>
    <div class="mobile-mega-menu">
      <div class="brands">
        <div class="mobile-link" @click="toggleMenu()">
          Модель
          <img class="shevron-down" :class="show_menu ? 'rotated' : ''" :src="'/img/shevron-down.svg'" alt="">
        </div>

        <div v-if="show_menu && brand !== null" class="brands-list-wrapper">
          <div class="brand-name" v-for="(model, index) in brand.models" @click="gotoModelPage(model)">
            {{ model.name }}
            <img :src="'/img/shevron-right.svg'" alt="">
          </div>
        </div>
      </div>
<!--      <a class="mobile-link mobile-menu-opener" href="javascript:void(0)" @click="toggleMenu">Модель</a>-->

    </div>
</template>

<script>
export default {
  props:['brand_id'],
    data: () => {
        return {
          brands:[],
          show_menu: false,
          options: {},
          brand: null,
          mobile:'yes'
        }
    },
    created() {
      this.brand = window.gon.brands.find((brand) => brand.id === parseInt(this.brand_id))
      this.options = window.gon.options
    },
    methods: {
      showFrm() {
        document.querySelector('.mobile-menu').remove('opened')
        document.querySelector('.header-btn1').click()
      },
      gotoModelPage(model) {
        window.location.href = `/presentations/${this.brand.slug}-${model.slug}`;
      },
      toggleMenu() {
        this.show_menu = !this.show_menu

      }
    },
    computed: {

    }
}
</script>

<style scoped>

.call-btn {
  text-decoration: none;

}

.mobile-link {
  padding-left: 20px;
}

.cars-header {
    font-size: 30px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #000;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 10px;
}

.shevron-down {
    width: 19px;
    transition: all 0.1s ease-in-out;
}

.rotated {
    rotate: 180deg;
    transition: all 0.2s ease-in-out;
}

.models {
    width: 100%;
    background-color: #ffffff;
}

.models-list {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
}

.model-wrapper {
    width: 100%;
}

.model {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 30px 0 10px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.model-img {
  width: 185px;
  height: 90px;
  transform: scaleX(-1);
}

.model-text {
    padding: 35px 30px 35px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000;
    width: 100%;
}

.brands-list-wrapper {
    width: 100%;
}

.brand-name {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 22px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #000;
}

.complectation-list {
    width: 100%;

}
.version-wrapper {
    padding: 0 30px 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bg-grey {
    background: #f7f7f7;
}

.version-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px 30px;
}

.version-price {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: rgba(0, 0, 0, 0.5);
}

.version-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #000;
}

@media (max-width: 432px) {

  .header-wrapper {
    padding: 20px 10px 0 10px;
  }
  .mobile-link {
    padding-left: 20px;
  }

  .model-img {
    padding-left: 10px;
  }

  .cars-header {
    padding-left: 10px;
  }
  .call-btn {
    width: 100px;
  }
  .chat-btn {
    width: 54px
  }
}
</style>