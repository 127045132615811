<template>
  <div class="login-container">
    <h1>Вход</h1>

    <div class="phone-wrapper" v-if="code">
      <small>Введите код из СМС</small>
      <div class="phone-error">
        {{error}}
      </div>
      <div class="code-wrapper">
        <input autofocus type="text" ref="code_1" id="code_1" v-model="code_1" maxlength="1" @keyup="focusNext" :disabled="loading">
        <input type="text" ref="code_2" id="code_2" v-model="code_2" maxlength="1" @keyup="focusNext" :disabled="loading">
        <input type="text" ref="code_3" id="code_3" v-model="code_3" maxlength="1" @keyup="focusNext" :disabled="loading">
        <span>•</span>
        <input type="text" ref="code_4" id="code_4" v-model="code_4" maxlength="1" @keyup="focusNext" :disabled="loading">
        <input type="text" ref="code_5" id="code_5" v-model="code_5" maxlength="1" @keyup="focusNext" :disabled="loading">
        <input type="text" ref="code_6" id="code_6" v-model="code_6" maxlength="1" @keyup="focusNext" :disabled="loading">
      </div>

      <div class="code-control" v-show="show_code_timer !== null">
        <span v-show="show_code_timer" id="code-timer">
          <span class="c-black">Следующий код можно будет запросить через</span>
          <span class="c-black" ref="code-timer"></span>
        </span>
        <a href="javascript:void(0)" @click="requestCode()" v-if="!show_code_timer">Запросить код повторно</a>
      </div>
    </div>
    <div class="phone-wrapper" v-if="!code">
      <small>Введите номер телефона</small>
      <div class="phone-error">
        {{error}}
      </div>
      <div class="phone-input-wrapper">
        <span class="country-code">+7</span>
        <imask-input v-model:typed="phone" :mask="'(000) 000-00-00'" placeholder='(900) 000-00-00' class="phone-input" @keyup.enter="requestCode()" />
        <span class="phone-arrow" :class="arrowClass" @click="requestCode()">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M1.37404 1C1.27916 1 1.18205 1.04028 1.10923 1.11847C0.963589 1.27485 0.963589 1.53075 1.10923 1.68714L6.09415 7.03969L1.18205 12.314C1.03641 12.4704 1.03641 12.7263 1.18205 12.8827C1.32769 13.0391 1.56602 13.0391 1.71166 12.8827L6.89077 7.32402C7.03641 7.16764 7.03641 6.91174 6.89077 6.75536L1.64104 1.11847C1.56602 1.03791 1.47114 1 1.37404 1Z" fill="black" fill-opacity="0.2"/>
            <path d="M1.03605 1.05032L1.10899 1.11825L1.03605 1.05032C0.854649 1.2451 0.854649 1.56051 1.03605 1.75529L5.9575 7.03969L1.10887 12.2459C0.927469 12.4407 0.927469 12.7561 1.10887 12.9509C1.29406 13.1497 1.59965 13.1497 1.78484 12.9509L6.96393 7.39219L6.96395 7.39217C7.14535 7.19739 7.14535 6.88198 6.96395 6.6872L1.71422 1.05032C1.62003 0.949174 1.49852 0.9 1.37404 0.9C1.25098 0.9 1.1275 0.952125 1.03605 1.05032Z" stroke="black" stroke-opacity="0.2" stroke-width="0.2"/>
          </g>
          </svg>
        </span>
      </div>
    </div>

  </div>

</template>

<script>
  import { IMaskComponent } from 'vue-imask';
  import api from '@/stuff/api';
  export default {
    components: {'imask-input': IMaskComponent},
    data: () => {
      return {
        phone: '',
        code: false,
        loading: false,
        api: api,
        error: '',
        code_1: '',
        code_2: '',
        code_3: '',
        code_4: '',
        code_5: '',
        code_6: '',
        distance: 0,
        show_code_timer: null
      }
    },
    mounted() {
      this.runTimer()
    },
    methods: {
      runTimer() {
        setInterval(()=>{
          let now = new Date().getTime();
          let target = this.distance - now;
          if (target > 0) {
            this.show_code_timer = true
            let days = Math.floor(target / (1000 * 60 * 60 * 24));
            let hours = Math.floor((target % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((target % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((target % (1000 * 60)) / 1000);
            // this.$refs['code-timer'].innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
            this.$refs['code-timer'].innerHTML = (minutes > 9 ? minutes : `0${minutes}`) + ":" + (seconds > 9 ? seconds : `0${seconds}`);
          } else {
            this.show_code_timer = false
          }

        }, 1000)
      },
      requestCode() {
        if (this.phone.length === 10 && !this.loading) {
          const _this = this
          this.error = ''
          this.loading = true
          fetch('/sessions/request_code', {
            headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
            method: 'POST',
            body: JSON.stringify({phone:this.phone})
          })
              .then(response => response.json())
              .then(data => {
                if (data.distance !== undefined) {
                  _this.code = true
                  _this.distance = data.distance
                } else {
                  _this.error = 'Неизвестный номер'
                }
                _this.loading = false
              })
              .catch(error => {
                _this.loading = false
                _this.error = 'Что-то пошло не так, попробуйте еще раз'
              })
        }
      },
      focusNext(event) {
        const el = event.target;
        let current_id = +el.id.split('_')[1]
        if (el.value.length === 1) {
          if (this.$refs[`code_${current_id+1}`]) this.$refs[`code_${current_id+1}`].focus()
        } else {
          if (this.$refs[`code_${current_id-1}`]) {
            this.$refs[`code_${current_id-1}`].focus()
          }

        }
      },
      sendCode() {
        const _this = this
        this.error = ''
        this.loading = true
        fetch('/sessions/login_sms_code', {
          headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
          method: 'POST',
          body: JSON.stringify({phone:this.phone,sms_code:this.joinedCode})
        })
            .then(response => response.json())
            .then(data => {
              if (data.login !== undefined) {
                window.location.href = "/my";
              } else {
                _this.error = 'Неверный код'
                _this.clearCode()
                setTimeout(() => _this.$refs['code_1'].focus(), 0);

              }
              _this.loading = false
            })
            .catch(error => {
              _this.loading = false
              _this.error = 'Что-то пошло не так, попробуйте еще раз'
            })
      },
      clearCode() {
        this.code_1 = ''
        this.code_2 = ''
        this.code_3 = ''
        this.code_4 = ''
        this.code_5 = ''
        this.code_6 = ''
      }
    },
    computed: {
      arrowClass() {
        return [`${this.phone.length === 10 ? 'good' : ''}`, `${this.loading ? 'rotate' : ''}`].join(' ')
      },
      joinedCode() {
        return `${this.code_1}${this.code_2}${this.code_3}${this.code_4}${this.code_5}${this.code_6}`
      }
    },
    watch: {
      joinedCode: function (val) {
        if (val.length === 6) {
          this.sendCode()
        }
      }
    }
  }
</script>

<style scoped>
  .login-container {
    padding: 150px 0 100px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-container h1 {
    font-size: 80px;
    margin-bottom: 100px;
  }

  .phone-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 32px;
  }


  .phone-wrapper small {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .phone-input-wrapper {
    display: flex;
    align-items: center;
    padding: 20px 8px;
    border-bottom: 1px solid #000;
  }

  .country-code {
    margin-right: 10px;
  }

  .phone-input {
    border: 1px solid transparent;
    width: 300px;
  }

  .phone-input:focus {
    outline: none;
  }

  .phone-input::placeholder {
    color: rgba(0, 0, 0, 0.24);
  }

  .phone-arrow {
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .phone-arrow.good {
    border: 1px solid rgba(0, 0, 0, 1);
  }

  .phone-arrow.good path {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  .phone-error {
    color: red;
    font-size: 14px;
  }


  @media (max-width: 450px) {
    .login-container h1 {
      font-size: 42px;
      margin-bottom: 40px;
    }
    .phone-wrapper {
      font-size: 28px;
      padding: 0 20px;
    }
    .phone-input {
      width: 260px;
    }

  }

  @media (max-width: 390px) {
    .login-container h1 {
      font-size: 36px;
    }
    .phone-wrapper {
      font-size: 26px;
    }
  }



  .code-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 40px;
  }

  .code-wrapper input[type="text"] {
    width: 60px;
    font-size: 32px;
    padding: 20px 8px;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
  }

  .code-wrapper input[type="text"]:focus {
    outline: none;
  }


  .code-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      color: rgba(0, 0, 0, 0.20);
      font-size: 16px;
      text-decoration: none;
    }
  }

  #code-timer {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 450px) {
    .code-wrapper {
      justify-content: space-between;
    }

    .code-wrapper input[type="text"] {
      width: 40px;
    }
  }

</style>
