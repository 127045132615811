const car = {
    brand: 'lixiang',
    model: 'L9',
    brand_id: 13,
    model_id: 114,
    mirrored_photos: true,
    equipments: [
        {title:'Pro',price:6500000,img:'/configurator/13/114/L9_pro.jpg',slug:'pro',default_color_id:1,default_wheel_id:3},
        {title:'Ultra',price:8500000,img:'/configurator/13/114/L9_ultra.jpg',slug:'ultra',default_color_id:6,default_wheel_id:4}
    ],
    colors: [
        {id:1,title:'Gold metallic',img:'/configurator/13/114/colors/1.png',price:0},
        {id:2,title:'Silver Metallic',img:'/configurator/13/114/colors/2.png',price:0},
        {id:3,title:'Grey Metallic',img:'/configurator/13/114/colors/3.png',price:0},
        {id:4,title:'Black metallic',img:'/configurator/13/114/colors/4.png',price:0},
        {id:5,title:'Green Special Edition Pearl',img:'/configurator/13/114/colors/5.png',price:0},
        {id:6,title:'Purple Special Edition',img:'/configurator/13/114/colors/6.png',price:0},
    ],
    wheels: [
        {id:3,title:'21-inch silver-gray two-tone',img:'/configurator/13/114/wheels/1.png',price:0},
        {id:4,title:'21-inch black and gray two-tone',img:'/configurator/13/114/wheels/2.png',price:0}
    ],
    salons: [
        {id:1,title:'Black and white',img:'/configurator/13/114/colors_salon/1.png',price:0},
        {id:2,title:'Black and orange',img:'/configurator/13/114/colors_salon/2.png',price:0},
        {id:3,title:'Black sports',img:'/configurator/13/114/colors_salon/3.png',price:0},
    ]
}

export { car as default };