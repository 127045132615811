<template>
  <div class="buy-new">
    <div class="content-container">
      <h3>Покупайте за рубежом. <span class="c-grey2">По-новому.</span></h3>
    </div>
    <hover-buy-new v-if="width > 1000" :content="content" />
    <slider-buy-new v-else :content="content" />
    <link rel="preload" as="image" :href="`/img/buy_new/${index}.webp`"  v-for="(item,index) in content"/>
  </div>
</template>

<script>
  import hoverBuyNew from './hover.vue'
  import sliderBuyNew from './slider.vue'
  export default {
    components: {hoverBuyNew,sliderBuyNew},
    data: ()=> {
      return {
        width:1920,
        content: [
          {title: 'Следите за доставкой.', description: 'Вы всегда знаете, где находится Ваш автомобиль.'},
          {title: 'Безбумажный документооборот.', description: 'Подписывайте документы онлайн.'},
          {title: 'Безопасная сделка.', description: 'Покупайте спокойно. Банк обеспечит безопасность.'},
          {title: '30 брендов, 140 моделей, 761 комплектация.', description: 'Покупайте то, что нравится, а не то, что предлагают.'},
          {title: 'Не платите повышенные пошлины.', description: 'Экономьте до 20%, потому что можно.'},
        ]
      }
    },
    created() {
      this.width = window.innerWidth;
      window.addEventListener('resize', ()=>{
        this.width = window.innerWidth;
      })
    }
  }
</script>

<style scoped>
  .buy-new {
    margin-bottom: 100px;
  }

  h3 {
    margin-bottom: 80px;
  }

  @media (max-width: 1500px) {
    h3 {margin-bottom: 50px;}
  }

  @media (max-width: 500px) {
    h3 {margin-bottom: 40px;}
  }

</style>