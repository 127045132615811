<template>
  <div class="car-config-color w-100">
    <div class="cc-color-car">
      <img :src="wheelImgUrl" :class="store.car.mirrored_photos ? 'mirrored' : ''">
    </div>

    <div class="cc-color-selector">
      <div class="cc-color-picker cc-wheel-picker" v-for="wheel in store.currentWheels"
           :class="wheel.id === store.selected_wheel ? 'selected' : ''" @click="store.selectWheel(wheel.id)">
        <img :src="`${wheel.img}`">
      </div>
    </div>
    <div class="cc-color-title">
      {{store.currentWheel.title}}
    </div>
    <div class="cc-color-price">
      {{ wheelPrice }}
    </div>

  </div>
</template>

<script>
import {useConfiguratorStore} from "@/stores/configurator.js";
export default {
  data: ()=> {
    return {
      store: useConfiguratorStore()
    }
  },
  computed: {
    wheelPrice() {
      if (this.store.currentWheel.price > 0) {
        return Intl.NumberFormat('ru-RU', {style: 'currency',currency: 'RUB',minimumFractionDigits: 0}).format(this.store.currentWheel.price)
      } else {
        return '\xa0'
      }
    },
    wheelImgUrl() {
      return `/configurator/${this.store.car.brand_id}/${this.store.car.model_id}/img/${this.store.car.model}-${this.store.currentCar.slug}-${this.store.selected_color}-${this.store.selected_wheel}-1.webp`
    }
  }
}
</script>

<style>
  .cc-wheel-picker {
    width: 60px;
    height: 60px;
  }
</style>