const car = {
    brand: 'lixiang',
    model: 'MEGA',
    brand_id: 13,
    model_id: 115,
    mirrored_photos: true,
    equipments: [
        {title:'Ultra',price:8500000,img:'/configurator/13/115/MEGA_ultra.jpg',slug:'ultra',default_color_id:1,default_wheel_id:1}
    ],
    colors: [
        {id:1,title:'Panda White Pearl',img:'/configurator/13/115/colors/1.png',price:0},
        {id:2,title:'Elephant Grey Special Edition Pearl',img:'/configurator/13/115/colors/2.png',price:122000}
    ],
    wheels: [
        {id:1,title:'18-inch black and gray two-tone low wind resistance wheels',img:'/configurator/13/115/wheels/1.png',price:0}
    ],
    salons: [
        {id:1,title:'White interior',img:'/configurator/13/115/colors_salon/1.png',price:0}
    ]
}

export { car as default };