import { defineStore } from 'pinia'


export const useConfiguratorStore = defineStore('configurator', {
    state: () => {
        return {
            country:'',
            step: 1,
            selected_car:null,
            selected_color:1,
            selected_wheel:1,
            selected_salon:1,
            car: null,
            finish: false
        }
    },
    getters: {
        currentCar(state) {
            if (state.selected_car !== null) {
                return state.car.equipments[state.selected_car]
            } else {
                return null
            }
        },
        currentColor(state) {
            if (state.selected_color !== null) {
                return state.car.colors.find(color => color.id === state.selected_color)
            } else {
                return null
            }
        },
        currentWheel(state) {
            if (state.selected_wheel !== null) {
                return state.car.wheels.find(wheel => wheel.id === state.selected_wheel)
            } else {
                return null
            }
        },
        currentSalon(state) {
            if (state.selected_salon !== null) {
                return state.car.salons.find(salon => salon.id === state.selected_salon)
            } else {
                return null
            }
        },
        totalPrice(state) {
            if (state.currentCar !== null) {
                return state.currentCar.price + state.currentColor.price + state.currentWheel?.price + state.currentWheel?.price
            } else {
                return 0
            }
        },
        currentColors(state) {
            if (state.currentCar === null)  {
                return []
            } else {
                if (state.currentCar.colors !== undefined && state.currentCar.colors.length > 0) {
                    return state.car.colors.filter(color => state.currentCar.colors.includes(color.id))
                } else {
                    return state.car.colors
                }
            }
        },
        currentWheels(state) {
            if (state.currentCar === null)  {
                return []
            } else {
                if (state.currentCar.wheels !== undefined && state.currentCar.wheels.length > 0) {
                    return state.car.wheels.filter(wheel => state.currentCar.wheels.includes(wheel.id))
                } else {
                    return state.car.wheels
                }
            }
        },
        currentSalons(state) {
            if (state.currentCar === null)  {
                return []
            } else {
                if (state.currentCar.salons !== undefined && state.currentCar.salons.length > 0) {
                    return state.car.salons.filter(salon => state.currentCar.salon.includes(salon.id))
                } else {
                    return state.car.salons
                }
            }
        },
        carDescription(state) {
            if (state.step > 4) {
                return `Модель: ${state.car.brand} ${state.car.model}, комплектация: ${state.currentCar.title}, цвет: ${state.currentColor.title}, диски: ${state.currentWheel.title}, салон ${state.currentSalon.title}`;
            } else {
                return ''
            }

        }
    },
    actions: {
        selectCar(index) {
            this.selected_car = index
            if (this.currentCar.default_color_id !== undefined) {
                let color = this.car.colors.find(c => c.id === this.currentCar.default_color_id)
                this.selected_color = color.id
            }

            if (this.currentCar.default_wheel_id !== undefined) {
                let wheel = this.car.wheels.find(w => w.id === this.currentCar.default_wheel_id)
                this.selected_wheel = wheel.id
            }

            if (this.currentCar.default_salon_id !== undefined) {
                let salon = this.car.salons.find(salon => salon.id === this.currentCar.default_salon_id)
                this.selected_salon = salon.id
            }

            this.step = 2
        },
        selectColor(id) {
            this.selected_color = id
        },
        selectWheel(id) {
            this.selected_wheel = id
        },
        selectSalon(id) {
            this.selected_salon = id
        },
        switchStep(step) {
            this.step = step
        },
        nextStep() {
          this.step += 1
        },
        stepBack() {
            if (this.step > 1) {
                this.step -= 1
            }
        }
    }
})