<template>
  <div class="car-config-color w-100">
    <div class="cc-color-car">
      <img :src="colorImgUrl" :class="store.car.mirrored_photos ? 'mirrored' : ''">
    </div>

    <div class="cc-color-selector">
      <div class="cc-color-picker" v-for="color in store.currentColors"
           :class="color.id === store.selected_color ? 'selected' : ''" @click="store.selectColor(color.id)">
        <img :src="`${color.img}`">
      </div>
    </div>
    <div class="cc-color-title">
      {{store.currentColor.title}}
    </div>
    <div class="cc-color-price">
      {{ colorPrice }}
    </div>

  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore()
      }
    },
    computed: {
      colorPrice() {
        if (this.store.currentColor.price > 0) {
          return Intl.NumberFormat('ru-RU', {style: 'currency',currency: 'RUB',minimumFractionDigits: 0}).format(this.store.currentColor.price)
        } else {
          return '\xa0'
        }
      },
      colorImgUrl() {
        return `/configurator/${this.store.car.brand_id}/${this.store.car.model_id}/img/${this.store.car.model}-${this.store.currentCar.slug}-${this.store.selected_color}-${this.store.selected_wheel}-1.webp`
      }
    }
  }
</script>

<style>
  .cc-color-car {
    display: flex;
    justify-content: center;
  }

  .cc-color-car img {
    width: 450px;
    height: 150px;
    object-fit: cover
  }

  .cc-color-selector {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 30px 0;
  }

  .cc-color-picker {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    border: 2px solid transparent;
    padding:3px;
    user-select: none;
  }

  .cc-color-picker img {
    width: 100%;
  }

  .cc-color-picker.selected, .cc-color-picker:hover {
    border: 2px solid black;
  }

  .cc-color-title {
    color: rgba(0, 0, 0, 0.40);
    display: flex;
    justify-content: center;
  }

  .cc-color-price {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }


</style>