<template>
  <div class="car-config-color w-100">
    <div class="cc-color-car cc-salon">
      <img :src="salonImgUrl" class="br-10">
    </div>

    <div class="cc-color-selector">
      <div class="cc-color-picker" v-for="salon in store.currentSalons"
           :class="salon.id === store.selected_salon ? 'selected' : ''" @click="store.selectSalon(salon.id)">
        <img :src="`${salon.img}`">
      </div>
    </div>
    <div class="cc-color-title">
      {{store.currentSalon.title}}
    </div>
    <div class="cc-color-price">
      {{ salonPrice }}
    </div>

  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore()
      }
    },
    computed: {
      salonPrice() {
        if (this.store.currentSalon.price > 0) {
          return Intl.NumberFormat('ru-RU', {style: 'currency',currency: 'RUB',minimumFractionDigits: 0}).format(this.store.currentSalon.price)
        } else {
          return '\xa0'
        }
      },
      salonImgUrl() {
        return `/configurator/${this.store.car.brand_id}/${this.store.car.model_id}/salon/${this.store.car.model}-${this.store.currentCar.slug}-${this.store.selected_color}-${this.store.selected_salon}.webp`
      }
    }
  }
</script>

<style>
  .cc-salon img {
    width: 650px;
    height: 300px;
  }

  @media (max-width: 700px) {
    .cc-salon img {
      width: 100%;
      height: 300px;
    }
  }
</style>