<template>
  <div class="cc-review">
    <div class="cc-review-img-wrapper">
      <span @click="backView">
        <svg class="cc-review-arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="40" viewBox="0 0 20 40" fill="none">
          <path d="M18.7532 0C19.0695 0 19.3932 0.134268 19.6359 0.394906C20.1214 0.916181 20.1214 1.76918 19.6359 2.29045L3.01949 20.1323L19.3932 37.7135C19.8786 38.2348 19.8786 39.0878 19.3932 39.609C18.9077 40.1303 18.1133 40.1303 17.6278 39.609L0.364104 21.0801C-0.121368 20.5588 -0.121368 19.7058 0.364104 19.1845L17.8632 0.394906C18.1133 0.12637 18.4295 0 18.7532 0Z" fill="black" fill-opacity="0.4"/>
        </svg>
      </span>
      <div class="cc-review-img">
        <img :src="viewImg" :class="store.car.mirrored_photos ? 'mirrored' : ''" />
        <div class="cc-review-paginator">
          <div class="cc-review-view cur-pointer" :class="view === 1 ? 'selected' : ''" @click="changeView(1)"></div>
          <div class="cc-review-view cur-pointer" :class="view === 2 ? 'selected' : ''" @click="changeView(2)"></div>
          <div class="cc-review-view cur-pointer" :class="view === 3 ? 'selected' : ''" @click="changeView(3)"></div>
        </div>
      </div>
      <span @click="forwardView">
        <svg class="cc-review-arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="40" viewBox="0 0 20 40" fill="none">
          <path d="M1.24682 0C0.930527 0 0.60684 0.134268 0.364103 0.394906C-0.121368 0.916181 -0.121368 1.76918 0.364103 2.29045L16.9805 20.1323L0.60684 37.7135C0.121368 38.2348 0.121368 39.0878 0.60684 39.609C1.09231 40.1303 1.88672 40.1303 2.3722 39.609L19.6359 21.0801C20.1214 20.5588 20.1214 19.7058 19.6359 19.1845L2.13682 0.394906C1.88672 0.12637 1.57047 0 1.24682 0Z" fill="black" fill-opacity="0.4"/>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
  import {useConfiguratorStore} from "@/stores/configurator.js";
  export default {
    data: ()=> {
      return {
        store: useConfiguratorStore(),
        view: 1
      }
    },
    methods: {
      changeView(pos) {
        this.view = pos;
      },
      backView() {
        if (this.view > 1) {
          this.view -= 1;
        } else {
          this.view = 3;
        }
      },
      forwardView() {
        if (this.view < 3) {
          this.view += 1;
        } else {
          this.view = 1;
        }
      }
    },
    computed: {
      viewImg() {
        return `/configurator/${this.store.car.brand_id}/${this.store.car.model_id}/img/${this.store.car.model}-${this.store.currentCar.slug}-${this.store.selected_color}-${this.store.selected_wheel}-${this.view}.webp`
      }
    }
  }
</script>

<style>
  .cc-review-img-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    user-select: none;
    margin-bottom: 30px;
  }

  .cc-review-img-wrapper span {
    cursor: pointer;
  }

  .cc-review-arrow:hover path {
    fill-opacity: 1;
  }

  .cc-review-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cc-review-img img {
    width: 500px;
    height: 200px;
    object-fit: cover
  }

  .cc-review-paginator {
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }

  .cc-review-view {
    width: 86px;
    background: rgba(0, 0, 0, 0.20);
    height: 2px;
  }

  .cc-review-view.selected, .cc-review-view:hover {
    background: rgba(0, 0, 0, 0.60);
  }

  @media (max-width: 600px) {
    .cc-review-img-wrapper {
      gap: 10px;
    }

    .cc-review {
      width: 100%;
    }

    .cc-review-img img {
      width: calc(100% - 30px);
      height: 200px;
      object-fit: cover
    }
  }

</style>