<template>
  <div class="done-wrapper">
    <h1>Готово.</h1>
    <div class="dealers-subtitle2">Мы сделаем кое-какие дела <br>и свяжемся с Вами.</div>
  </div>
</template>

<script>
  export default {
    data: ()=> {
      return {

      }
    }
  }
</script>