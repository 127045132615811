<template>
  <div class="presentation-control">
    <a class="control-opener arrow-link" href="javascript:void(0)" @click="toggleModal">
      Презентация и цены
      <img class="shevron-connect" :src="`/img/shevron-right-white.svg`">
    </a>
    <modal-window @close="toggleModal" v-show="opened" />
  </div>
</template>

<script>
  import modalWindow from "@/components/presentation_control/modal.vue"
  export default {
    components: {modalWindow},
    data: ()=> {
      return {
        opened: false
      }
    },
    methods: {
      toggleModal() {
        this.opened = !this.opened
        if (this.opened) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
      }
    }
  }
</script>

<style>

  .presentation-control {
    margin-top: 80px;
  }

  .control-opener {
    text-decoration: none;
    color: #fff;
  }
</style>