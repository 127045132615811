<template>
  <div class="full-screen opened">
    <div class="full-screen-header">
      <span class="full-screen-logo">Terminal A</span>
      <div class="full-screen-closer" @click="closeMe">
        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
          <rect width="39" height="39" rx="19.5" fill="black"/>
          <line x1="11.6895" y1="10.8086" x2="29.3672" y2="28.4862" stroke="#F7F7F7"/>
          <line x1="10.9863" y1="28.4882" x2="28.664" y2="10.8106" stroke="#F7F7F7"/>
        </svg>
      </div>
    </div>
    <div class="full-screen-body">
      <div class="login-container">
        <h2>Выбирайте новый тип продаж.</h2>

        <div class="phone-wrapper" v-if="code && !show_url">
          <small class="c-black" style="font-size: 14px;">Введите код из СМС</small>
          <div class="phone-error">
            {{error}}
          </div>
          <div class="code-wrapper c-black">
            <input autofocus type="text" ref="code_1" id="code_1" v-model="code_1" maxlength="1" @keydown="focusNext" :disabled="loading">
            <input type="text" ref="code_2" id="code_2" v-model="code_2" maxlength="1" @keydown="focusNext" @keyup="catchBackspace" :disabled="loading">
            <input type="text" ref="code_3" id="code_3" v-model="code_3" maxlength="1" @keydown="focusNext" @keyup="catchBackspace" :disabled="loading">
            <span>•</span>
            <input type="text" ref="code_4" id="code_4" v-model="code_4" maxlength="1" @keydown="focusNext" @keyup="catchBackspace" :disabled="loading">
            <input type="text" ref="code_5" id="code_5" v-model="code_5" maxlength="1" @keydown="focusNext" @keyup="catchBackspace" :disabled="loading">
            <input type="text" ref="code_6" id="code_6" v-model="code_6" maxlength="1" @keyup="catchBackspace" :disabled="loading">
          </div>

          <div class="code-control" v-show="show_code_timer !== null">
            <span v-show="show_code_timer" id="code-timer">
              <span class="c-black">Следующий код можно будет запросить через</span>
              <span class="c-black" ref="code-timer"></span>
            </span>
            <a href="javascript:void(0)" @click="requestCode()" v-if="!show_code_timer">Запросить код повторно</a>
          </div>
        </div>

        <div class="phone-wrapper" v-if="code && show_url">
          <a :href="url" target="_blank" class="black-btn btn-h-white">Открыть презентацию</a>
        </div>

        <div class="p-content-wrapper" v-if="!code">
          <imask-input v-model:typed="phone" :mask="'(000) 000-00-00'" placeholder='Введите номер телефона' class="phone-input" @keyup.enter="requestCode()" />
          <label class="checkbox-button">
            <input class="checkbox-button__input" type="checkbox" v-model="agree">
            <span class="checkbox-button__control"></span>
            <span class="checkbox-button__label" style="color: rgba(0, 0, 0, 0.70);"> Я согласен с  <a href="/privacy" style="color: rgba(0, 0, 0, 0.70);" target="_blank">политикой конфиденциальности</a></span>
          </label>
          <a v-if="agree && phone.length > 9" href="javascript:void(0)" class="black-btn btn-h-white" @click="requestCode">Отправить</a>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
  import { IMaskComponent } from 'vue-imask';
  import api from '@/stuff/api';
  export default {
    components: {'imask-input': IMaskComponent},
    data: ()=> {
      return {
        phone: '',
        code: false,
        loading: false,
        api: api,
        error: '',
        code_1: '',
        code_2: '',
        code_3: '',
        code_4: '',
        code_5: '',
        code_6: '',
        distance: 0,
        show_code_timer: null,
        agree: false,
        url:'',
        show_url:false
      }
    },
    mounted() {
      this.runTimer()
    },
    methods: {
      closeMe() {
        this.$emit('close')
      },
      runTimer() {
        setInterval(()=>{
          let now = new Date().getTime();
          let target = this.distance - now;
          if (target > 0) {
            this.show_code_timer = true
            let days = Math.floor(target / (1000 * 60 * 60 * 24));
            let hours = Math.floor((target % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((target % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((target % (1000 * 60)) / 1000);
            // this.$refs['code-timer'].innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
            if (this.$refs['code-timer'] !== null) {
              this.$refs['code-timer'].innerHTML = (minutes > 9 ? minutes : `0${minutes}`) + ":" + (seconds > 9 ? seconds : `0${seconds}`);
            }

          } else {
            this.show_code_timer = false
          }

        }, 1000)
      },
      requestCode() {
        if (this.phone.length === 10 && !this.loading) {
          const _this = this
          this.error = ''
          this.loading = true
          fetch('/request_presentation_code', {
            headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
            method: 'POST',
            body: JSON.stringify({phone:this.phone,presentation: 'yes'})
          })
              .then(response => response.json())
              .then(data => {
                if (data.distance !== undefined) {
                  _this.code = true
                  _this.distance = data.distance
                } else {
                  _this.error = 'Неизвестный номер'
                }
                _this.loading = false
              })
              .catch(error => {
                _this.loading = false
                _this.error = 'Что-то пошло не так, попробуйте еще раз'
              })
        }
      },
      focusNext(event) {
        const el = event.target;
        let current_id = +el.id.split('_')[1]
        if (event.key !== 'Backspace') {
          if (el.value.length === 1) {
            if (this.$refs[`code_${current_id+1}`]) this.$refs[`code_${current_id+1}`].focus()
          } else {
            if (this.$refs[`code_${current_id-1}`]) {
              this.$refs[`code_${current_id-1}`].focus()
            }
          }
        }
      },
      catchBackspace(event) {
        if (event.key === 'Backspace') {
          event.target.value = ''
          const el = event.target;
          let current_id = +el.id.split('_')[1]
          if (this.$refs[`code_${current_id-1}`]) {
            this.$refs[`code_${current_id-1}`].focus()
          }
        }
      },
      clearCode() {
        this.code_1 = ''
        this.code_2 = ''
        this.code_3 = ''
        this.code_4 = ''
        this.code_5 = ''
        this.code_6 = ''
      },
      sendCode() {
        const _this = this
        this.error = ''
        this.loading = true
        fetch('/sms_code_presentation', {
          headers: {'content-type': 'application/json','X-Requested-With' : 'XMLHttpRequest','X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
          method: 'POST',
          body: JSON.stringify({phone:this.phone,sms_code:this.joinedCode})
        })
            .then(response => response.json())
            .then(data => {
              if (data.url !== undefined) {
                _this.url = data.url
                _this.show_url = true
              } else {
                _this.error = 'Неверный код'
                _this.clearCode()
                setTimeout(() => _this.$refs['code_1'].focus(), 0);
              }
              _this.loading = false
            })
            .catch(error => {
              _this.loading = false
              _this.error = 'Что-то пошло не так, попробуйте еще раз'
            })
      }
    },
    computed: {
      arrowClass() {
        return [`${this.phone.length === 10 ? 'good' : ''}`, `${this.loading ? 'rotate' : ''}`].join(' ')
      },
      joinedCode() {
        return `${this.code_1}${this.code_2}${this.code_3}${this.code_4}${this.code_5}${this.code_6}`
      }
    },
    watch: {
      joinedCode: function (val) {
        if (val.length === 6) {
          this.sendCode()
        }
      }
    }
  }
</script>

<style scoped>
  .phone-input {
    background: #F8F8F8;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
    width: 100%;
    font-size: 20px;
    padding: 8px;
    margin-bottom: 60px;
  }

  .phone-input:focus {
    outline: none;
  }

  .phone-input::placeholder {
    color: rgba(0, 0, 0, 0.24);
  }

  .checkbox-button {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .black-btn {
    font-size: 16px;
    flex-shrink: 0;
    width: fit-content;
  }

  .p-content-wrapper {
    display: flex;
    flex-direction: column;
  }

  .code-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 40px;
  }

  .code-wrapper input[type="text"] {
    width: 60px;
    font-size: 32px;
    padding: 20px 8px;
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
    background: #F8F8F8;
  }

  .code-wrapper input[type="text"]:focus {
    outline: none;
  }


  .code-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      color: rgba(0, 0, 0, 0.20);
      font-size: 16px;
      text-decoration: none;
    }
  }

  #code-timer {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .phone-error {
    color: red;
    font-size: 14px;
  }

  @media (max-width: 450px) {
    .code-wrapper {
      justify-content: space-between;
    }

    .code-wrapper input[type="text"] {
      width: 40px;
    }
  }


</style>