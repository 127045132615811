<template>
  <div class="country-selector">
    <div class="dealers-step">
      1 из 2
    </div>
    <h1>Выбирайте новый тип продаж.</h1>
    <div class="dealers-subtitle2">Какую страну Вы представляете?</div>
    <div class="countries-select-wrapper">
      <div class="countries-select">
        <div class="country-with-flag" @click="toggleOpened">
          <div class="left">
            <span v-html="flagSvg(store.country)"></span>
            {{countryName(store.country)}}
          </div>
          <div class="right" :class="opened ? 'rotated' : ''">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
              <path d="M18 1.06107C18 0.918736 17.9396 0.773078 17.8223 0.663847C17.5877 0.445384 17.2039 0.445384 16.9693 0.663847L8.94047 8.14123L1.02893 0.773078C0.794353 0.554615 0.410505 0.554615 0.175932 0.773078C-0.0586414 0.99154 -0.0586414 1.34903 0.175932 1.56749L8.51397 9.33615C8.74854 9.55462 9.13239 9.55462 9.36697 9.33615L17.8223 1.46157C17.9431 1.34903 18 1.20671 18 1.06107Z" fill="black"/>
            </svg>
          </div>
        </div>
        <div class="countries-list" v-show="opened">
          <div class="country-with-flag select-country" :class="country.code === store.country ? 'selected' : ''" v-for="country in countries" @click.stop="selectCountry(country)">
            <div class="left">
              <span v-html="flagSvg(country.code)"></span>
              {{countryName(country.code)}}
            </div>
            <div class="right">
              <svg v-if="country.code === store.country" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.3346 4L6.0013 11.3333L2.66797 8" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <button v-if="store.country.length > 0" class="black-btn btn-h-white" @click="store.switchScreen('acc')">Далее</button>
    </div>


  </div>
</template>

<script>
  import {useDealerAuthStore} from "@/stores/dealer_auth.js";
  export default {
    data: ()=> {
      return {
        store: useDealerAuthStore(),
        countries: [
          {name:'Россия',code:'ru'},
          {name:'Беларусь',code:'bel'}
        ],
        opened: false,
        current_country: {}
      }
    },
    methods: {
      toggleOpened() {
        this.opened = !this.opened;
      },
      selectCountry(country) {
        this.store.country = country.code;
        this.toggleOpened();
      },
      flagSvg(code) {
        if (code === 'ru') {
          return '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" fill="none">\n' +
              '  <g clip-path="url(#clip0_5717_7374)">\n' +
              '    <path d="M0 0.625H25V6.875H0V0.625Z" fill="white"/>\n' +
              '    <path d="M0 6.875H25V13.125H0V6.875Z" fill="#0039A6"/>\n' +
              '    <path d="M0 13.125H25V19.375H0V13.125Z" fill="#D52B1E"/>\n' +
              '  </g>\n' +
              '  <defs>\n' +
              '    <clipPath id="clip0_5717_7374">\n' +
              '      <rect width="25" height="18.75" fill="white" transform="translate(0 0.625)"/>\n' +
              '    </clipPath>\n' +
              '  </defs>\n' +
              '</svg>'
        } else if (code === 'bel') {
          return '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" fill="none">\n' +
              '  <g clip-path="url(#clip0_5717_7465)">\n' +
              '    <path d="M0 0.625H25V19.375H0V0.625Z" fill="#CE1720"/>\n' +
              '    <mask id="mask0_5717_7465" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="5" height="20">\n' +
              '      <path d="M0.195312 0.625H4.32695V13.2916H4.49222V19.2082L4.32695 19.3749H0.195312V0.625Z" fill="white"/>\n' +
              '    </mask>\n' +
              '    <g mask="url(#mask0_5717_7465)">\n' +
              '      <path d="M0.940211 0.625V0.916665H0.754287V1.20833H0.527047V1.54166H0.361781V1.81249H0.03125V1.125H0.361781V0.749999H0.03125V0.625H0.940211ZM1.47732 2.22916V2.54165H1.31206V2.79165H1.14679V2.54165H0.981527V2.22916H0.816262V1.81249H0.981527V1.49999H1.14679V1.25H1.31206V1.49999H1.47732V1.81249H1.64259V2.22916H1.47732ZM1.12613 2.20832H1.33272V1.83333H1.12613V2.20832ZM0.589022 4.43748H0.754287V4.06248H0.589022V4.43748ZM1.70456 4.43748H1.86983V4.06248H1.70456V4.43748ZM0.03125 6.97913H0.320465V7.24996H0.444414V7.58329H0.630338V7.89579H0.878236V7.58329H1.06416V7.24996H1.22943V6.97913H0.981527V6.66663H1.41535V6.87496H1.68391V7.22913H1.91115V7.62496H1.74588V7.91662H1.60127V8.18745H1.47732V8.47912H1.2914V8.72912H1.14679V8.95828H0.960869V9.24995H0.692313V8.93745H0.506389V8.64578H0.361781V8.45828H0.03125V7.97912H0.361781V7.56246H0.03125V6.97913Z" fill="white"/>\n' +
              '      <path d="M3.58323 0.625V0.916665H3.76915V1.20833H3.99639V1.54166H4.16166V1.81249H4.49219V1.125H4.16166V0.749999H4.49219V0.625H3.58323ZM3.04611 2.22916V2.54165H3.21138V2.79165H3.37664V2.54165H3.54191V2.22916H3.70718V1.81249H3.54191V1.49999H3.37664V1.25H3.21138V1.49999H3.04611V1.81249H2.88085V2.22916H3.04611ZM3.3973 2.20832H3.19072V1.83333H3.3973V2.20832ZM3.93442 4.43748H3.76915V4.06248H3.93442V4.43748ZM2.81887 4.43748H2.65361V4.06248H2.81887V4.43748ZM4.49219 6.97913H4.20297V7.24996H4.07902V7.58329H3.8931V7.89579H3.6452V7.58329H3.45928V7.24996H3.29401V6.97913H3.54191V6.66663H3.10809V6.87496H2.83953V7.22913H2.61229V7.62496H2.77756V7.91662H2.92216V8.18745H3.04611V8.47912H3.23204V8.72912H3.37664V8.95828H3.56257V9.24995H3.83112V8.93745H4.01705V8.64578H4.16166V8.45828H4.49219V7.97912H4.16166V7.56246H4.49219V6.97913Z" fill="white"/>\n' +
              '      <path d="M2.1797 0.625V1.29166H2.34497V0.625H3.00603V0.916665H2.84077V1.20833H2.59287V1.54166H2.4276V1.81249H2.09707V1.54166H1.9318V1.20833H1.68391V0.916665H1.51864V0.625H2.1797ZM2.13839 6.3333V6.10413H2.01444V5.8333H1.86983V5.54164H1.70456V5.24997H1.5393V5.04164H1.35337V4.74997H1.10548V5.04164H0.919553V5.24997H0.774945V5.54164H0.60968V5.8333H0.48573V6.10413H0.361781V6.4583H0.03125V5.54164H0.361781V5.12497H0.03125V4.43748H0.361781V4.72914H0.568363V5.02081H0.774945V4.72914H0.981527V4.43748H1.14679V4.06248H0.981527V3.77081H0.774945V3.47915H0.568363V3.77081H0.361781V4.06248H0.03125V3.20832H0.361781V2.81249H0.03125V2.22916H0.361781V2.49999H0.527047V2.83332H0.754287V3.10415H0.940211V3.41665H1.08482V3.66665H1.37403V3.41665H1.51864V3.10415H1.70456V2.83332H1.9318V2.49999H2.09707V2.22916H2.4276V2.49999H2.59287V2.83332H2.82011V3.10415H3.00603V3.41665H3.15064V3.66665H3.43985V3.41665H3.58446V3.10415H3.77038V2.83332H3.99762V2.49999H4.16289V2.22916H4.49342V2.81249H4.16289V3.20832H4.49342V4.06248H4.16289V3.77081H3.95631V3.47915H3.74973V3.77081H3.54314V4.06248H3.37788V4.43748H3.54314V4.72914H3.74973V5.02081H3.95631V4.72914H4.16289V4.43748H4.49342V5.12497H4.16289V5.54164H4.49342V6.4583H4.16289V6.10413H4.03894V5.8333H3.91499V5.54164H3.74973V5.24997H3.60512V5.04164H3.4192V4.74997H3.1713V5.04164H2.98537V5.24997H2.82011V5.54164H2.65484V5.8333H2.51023V6.10413H2.38629V6.3333H2.13839ZM2.1797 2.85415V3.41665H2.34497V2.85415H2.1797ZM2.09707 4.06248V3.77081H1.89049V3.47915H1.68391V3.77081H1.47732V4.06248H1.31206V4.43748H1.47732V4.72914H1.68391V5.02081H1.89049V4.72914H2.09707V4.43748H2.4276V4.72914H2.63418V5.02081H2.84077V4.72914H3.04735V4.43748H3.21261V4.06248H3.04735V3.77081H2.84077V3.47915H2.63418V3.77081H2.4276V4.06248H2.09707ZM2.1797 5.02081V5.5833H2.34497V5.02081H2.1797Z" fill="white"/>\n' +
              '      <path d="M0.940211 19.375V19.0833H0.754287V18.7917H0.527047V18.4583H0.361781V18.1875H0.03125V18.875H0.361781V19.25H0.03125V19.375H0.940211ZM1.47732 17.7708V17.4583H1.31206V17.2083H1.14679V17.4583H0.981527V17.7708H0.816262V18.1875H0.981527V18.5H1.14679V18.75H1.31206V18.5H1.47732V18.1875H1.64259V17.7708H1.47732ZM1.12613 17.7917H1.33272V18.1667H1.12613V17.7917ZM0.589022 15.5625H0.754287V15.9375H0.589022V15.5625ZM1.70456 15.5625H1.86983V15.9375H1.70456V15.5625ZM0.03125 13.0209H0.320465V12.75H0.444414V12.4167H0.630338V12.1042H0.878236V12.4167H1.06416V12.75H1.22943V13.0209H0.981527V13.3334H1.41535V13.125H1.68391V12.7709H1.91115V12.375H1.74588V12.0834H1.60127V11.8125H1.47732V11.5209H1.2914V11.2709H1.14679V11.0417H0.960869V10.7501H0.692313V11.0626H0.506389V11.3542H0.361781V11.5417H0.03125V12.0209H0.361781V12.4375H0.03125V13.0209Z" fill="white"/>\n' +
              '      <path d="M3.58323 19.375V19.0833H3.76915V18.7917H3.99639V18.4583H4.16166V18.1875H4.49219V18.875H4.16166V19.25H4.49219V19.375H3.58323ZM3.04611 17.7708V17.4583H3.21138V17.2083H3.37664V17.4583H3.54191V17.7708H3.70718V18.1875H3.54191V18.5H3.37664V18.75H3.21138V18.5H3.04611V18.1875H2.88085V17.7708H3.04611ZM3.3973 17.7917H3.19072V18.1667H3.3973V17.7917ZM3.93442 15.5625H3.76915V15.9375H3.93442V15.5625ZM2.81887 15.5625H2.65361V15.9375H2.81887V15.5625ZM4.49219 13.0209H4.20297V12.75H4.07902V12.4167H3.8931V12.1042H3.6452V12.4167H3.45928V12.75H3.29401V13.0209H3.54191V13.3334H3.10809V13.125H2.83953V12.7709H2.61229V12.375H2.77756V12.0834H2.92216V11.8125H3.04611V11.5209H3.23204V11.2709H3.37664V11.0417H3.56257V10.7501H3.83112V11.0626H4.01705V11.3542H4.16166V11.5417H4.49219V12.0209H4.16166V12.4375H4.49219V13.0209Z" fill="white"/>\n' +
              '      <path d="M2.1797 19.375V18.7083H2.34497V19.375H3.00603V19.0833H2.84077V18.7917H2.59287V18.4583H2.4276V18.1875H2.09707V18.4583H1.9318V18.7917H1.68391V19.0833H1.51864V19.375H2.1797ZM2.13839 13.6667V13.8959H2.01444V14.1667H1.86983V14.4584H1.70456V14.75H1.5393V14.9584H1.35337V15.25H1.10548V14.9584H0.919553V14.75H0.774945V14.4584H0.60968V14.1667H0.48573V13.8959H0.361781V13.5417H0.03125V14.4584H0.361781V14.875H0.03125V15.5625H0.361781V15.2709H0.568363V14.9792H0.774945V15.2709H0.981527V15.5625H1.14679V15.9375H0.981527V16.2292H0.774945V16.5209H0.568363V16.2292H0.361781V15.9375H0.03125V16.7917H0.361781V17.1875H0.03125V17.7708H0.361781V17.5H0.527047V17.1667H0.754287V16.8958H0.940211V16.5834H1.08482V16.3334H1.37403V16.5834H1.51864V16.8958H1.70456V17.1667H1.9318V17.5H2.09707V17.7708H2.4276V17.5H2.59287V17.1667H2.82011V16.8958H3.00603V16.5834H3.15064V16.3334H3.43985V16.5834H3.58446V16.8958H3.77038V17.1667H3.99762V17.5H4.16289V17.7708H4.49342V17.1875H4.16289V16.7917H4.49342V15.9375H4.16289V16.2292H3.95631V16.5209H3.74973V16.2292H3.54314V15.9375H3.37788V15.5625H3.54314V15.2709H3.74973V14.9792H3.95631V15.2709H4.16289V15.5625H4.49342V14.875H4.16289V14.4584H4.49342V13.5417H4.16289V13.8959H4.03894V14.1667H3.91499V14.4584H3.74973V14.75H3.60512V14.9584H3.4192V15.25H3.1713V14.9584H2.98537V14.75H2.82011V14.4584H2.65484V14.1667H2.51023V13.8959H2.38629V13.6667H2.13839ZM2.1797 17.1458V16.5834H2.34497V17.1458H2.1797ZM2.09707 15.9375V16.2292H1.89049V16.5209H1.68391V16.2292H1.47732V15.9375H1.31206V15.5625H1.47732V15.2709H1.68391V14.9792H1.89049V15.2709H2.09707V15.5625H2.4276V15.2709H2.63418V14.9792H2.84077V15.2709H3.04735V15.5625H3.21261V15.9375H3.04735V16.2292H2.84077V16.5209H2.63418V16.2292H2.4276V15.9375H2.09707ZM2.1797 14.9792V14.4167H2.34497V14.9792H2.1797Z" fill="white"/>\n' +
              '      <path d="M0.03125 9.12434H0.361781V9.41601H0.506389V9.58267H0.671654V9.87434H0.816262V10.1243H0.671654V10.416H0.506389V10.5827H0.361781V10.8743H0.03125V9.12434ZM4.49342 9.12434V10.8743H4.16289V10.5827H4.01828V10.416H3.85302V10.1243H3.70841V9.87434H3.85302V9.58267H4.01828V9.41601H4.16289V9.12434H4.49342ZM1.47732 10.1868H1.64259V9.81184H1.47732V10.1868ZM3.04735 10.1868V9.81184H2.88208V10.1868H3.04735ZM2.1797 8.95768H2.34497V8.58268H2.1797V8.95768ZM2.1797 11.041V11.416H2.34497V11.041H2.1797ZM1.14679 9.47851H1.43601V9.24934H1.64259V9.04101H1.74588V8.83268H1.86983V8.54101H2.0351V8.24935H2.11773V7.97852H2.40694V8.24935H2.48958V8.54101H2.65484V8.83268H2.77879V9.04101H2.88208V9.24934H3.08866V9.47851H3.37788V10.5202H3.08866V10.7493H2.88208V10.9577H2.77879V11.166H2.65484V11.4577H2.48958V11.7493H2.40694V12.0202H2.11773V11.7493H2.0351V11.4577H1.86983V11.166H1.74588V10.9577H1.64259V10.7493H1.43601V10.5202H1.14679V9.47851ZM2.1797 9.66601V9.35351H2.01444V9.14517H1.84917V9.66601H2.01444V9.8535H2.11773V10.1452H2.01444V10.3327H1.84917V10.8535H2.01444V10.6452H2.1797V10.3327H2.34497V10.6452H2.51023V10.8535H2.6755V10.3327H2.51023V10.1452H2.40694V9.8535H2.51023V9.66601H2.6755V9.14517H2.51023V9.35351H2.34497V9.66601H2.1797Z" fill="white"/>\n' +
              '    </g>\n' +
              '    <path d="M4.29688 13.125H25V19.375H4.29688V13.125Z" fill="#007C30"/>\n' +
              '  </g>\n' +
              '  <defs>\n' +
              '    <clipPath id="clip0_5717_7465">\n' +
              '      <rect width="25" height="18.75" fill="white" transform="translate(0 0.625)"/>\n' +
              '    </clipPath>\n' +
              '  </defs>\n' +
              '</svg>'
        } else {
          return '<svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">\n' +
              '  <path d="M5.25 13.625H29.75M5.25 22.375H29.75M16.7708 4.875C14.314 8.81193 13.0115 13.3594 13.0115 18C13.0115 22.6406 14.314 27.1881 16.7708 31.125M18.2292 4.875C20.686 8.81193 21.9885 13.3594 21.9885 18C21.9885 22.6406 20.686 27.1881 18.2292 31.125M4.375 18C4.375 19.7236 4.71449 21.4303 5.37408 23.0227C6.03367 24.6151 7.00045 26.062 8.21922 27.2808C9.43799 28.4995 10.8849 29.4663 12.4773 30.1259C14.0697 30.7855 15.7764 31.125 17.5 31.125C19.2236 31.125 20.9303 30.7855 22.5227 30.1259C24.1151 29.4663 25.562 28.4995 26.7808 27.2808C27.9995 26.062 28.9663 24.6151 29.6259 23.0227C30.2855 21.4303 30.625 19.7236 30.625 18C30.625 14.519 29.2422 11.1806 26.7808 8.71922C24.3194 6.25781 20.981 4.875 17.5 4.875C14.019 4.875 10.6806 6.25781 8.21922 8.71922C5.75781 11.1806 4.375 14.519 4.375 18Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>\n' +
              '</svg>'
        }
      },
      countryName(code) {
        if (code === 'ru') {
          return 'Россия'
        } else if (code === 'bel') {
          return 'Беларусь'
        } else {
          return 'Выберите страну'
        }
      },
    },
    computed: {

    }
  }
</script>

<style>
  .countries-select-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 150px;
    z-index: 10;
  }

  .countries-select {
    position: relative;
    z-index: 99;
  }
  .country-with-flag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    user-select: none;
    width: 400px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
    z-index: 99999;
  }

  @media (max-width: 630px) {
    .countries-select-wrapper {
      width: 100%;
      padding: 0 40px;
    }

    .countries-select {
      width: 100%;
    }

    .country-with-flag {
      width: 100%;
    }

    .countries-list {
      width: 100%;
    }

  }

  .country-with-flag .left {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 20px;
  }

  .countries-list {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 99;
    background: #fff;
  }

  .select-country {
    border-bottom: 1px solid #00000019;
    background: #FFF;
  }

  .select-country.selected {
    background: #00000007;

  }

  .select-country:hover {
    border-bottom: 1px solid #00000019;
    background: #00000007;
  }

  @media (max-width: 380px) {
    .countries-select-wrapper {
      padding: 0 20px;
    }
  }
</style>