<template>
    <div class="mega-menu">
      <a href="javascript:void(0)" class="btn-cars" @click="toggleMenu()" style="white-space: nowrap;">
        Автомобили
        <svg class="mega-menu-arrow" :class="show_menu ? 'rotated' : ''" style="margin-left: 8px" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 0.374046C12 0.279158 11.9597 0.182052 11.8815 0.109231C11.7251 -0.0364106 11.4692 -0.0364106 11.3129 0.109231L5.96031 5.09415L0.685951 0.182052C0.529568 0.03641 0.273669 0.03641 0.117287 0.182052C-0.0390961 0.327694 -0.0390961 0.566017 0.117287 0.711658L5.67598 5.89077C5.83236 6.03641 6.08826 6.03641 6.24464 5.89077L11.8815 0.641044C11.9621 0.566017 12 0.47114 12 0.374046Z" fill="white"/>
        </svg>
      </a>
      <div class="nav-container" @click="clickOutside">
        <div v-if="show_menu" class="brands-list  ">
          <div class="section-header b rands-header">
            Марка
            <img v-if="brand_id == null" @click="closeMenu()" :src="'/img/close-menu.svg'" style="cursor: pointer">
          </div>

          <div class="list-wrapper">
            <div class="brand-name" v-for="(brand, index) in brands" @click="selectBrand(brand.id)" :id="`brand_${brand.id}`" :class="brand.id === brand_id ? 'selected' : ''">
              {{ brand.name }}
              <img :src="'/img/shevron-right.svg'" alt="">
            </div>
          </div>
        </div>

        <div class="models-list" v-if="brand_id !== null">
          <div class="section-header models-header">
            Модель
            <img v-if="currentModel == null" @click="closeMenu()" :src="'/img/close-menu.svg'" style="cursor: pointer">
          </div>
          <div class="list-wrapper">
            <div class="model-name" v-for="(model, index) in currentModels" @click="selectModel(model.id)" :id="`model_${model.id}`" :class="model.id === model_id ? 'selected' : ''">
              <img class="model-img" :src="model.photo" alt="">
              <div class="model-text">
                <span class="model-name-text">{{ model.name }}</span>
                <img :src="'/img/shevron-right.svg'">
              </div>
            </div>
          </div>


        </div>
        <div v-if="model_id !== null" class="complectation-list">
          <div class="section-header complectation-header">
            &nbsp;
            <img @click="closeMenu()" :src="'/img/close-menu.svg'" style="cursor: pointer">
          </div>
          <div class="list-wrapper">
            <div class="version-wrapper" v-for="version in currentVersions">
              <div class="version-details">
                <span class="version-name">{{ version.name }}</span>
                <span class="version-price" v-if="version.price > 0">
                  от {{ Intl.NumberFormat('ru-RU', {style: 'currency',currency: 'RUB',minimumFractionDigits: 0}).format(version.price) }}
                </span>
              </div>
              <div class="buttons">
                <a href="javascript:void(0)" class="chat-btn" @click="showFrm">Цена</a>
<!--                <a :href="`tel:${options.phone}`" class="chat-btn">Позвонить</a>-->
<!--                <a class="chat-btn" :href="`https://wa.me/7${options.whatsapp}`" target="_blank">Чат</a>-->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>



</template>

<script>
  export default {
      data: () => {
          return {
            brands:[],
            brand_id: null,
            model_id: null,
            show_menu: false,
            options: {}
          }
      },
    created() {
      this.brands = window.gon.brands
      this.options = window.gon.options
    },
    methods: {
      clickOutside(event) {
        // console.log(event.target)
        if (event.target.classList.contains('nav-container')) {
          this.closeMenu()
        }
      },
      showFrm() {
        this.closeMenu()
        document.querySelector('.header-btn1').click()
      },
          selectBrand(id) {
              this.brand_id = id
              this.model_id = null
          },
          selectModel(id) {
              this.model_id = id
          },
          closeMenu() {
              this.model_id = null
              this.brand_id = null
              this.show_menu = false
          },
          toggleMenu() {
            this.model_id = null
            this.brand_id = null
            this.show_menu = !this.show_menu
            if (this.show_menu && window.quick_catalog !== undefined) {
              let [brand_id,model_id] = window.quick_catalog.split('-')
              window.quick_catalog = undefined
              this.brand_id = parseInt(brand_id)
              this.model_id = parseInt(model_id)
              setTimeout(()=>{
                document.getElementById(`brand_${brand_id}`).scrollIntoView()
                document.getElementById(`model_${model_id}`).scrollIntoView()
              },200)
            }
          }
      },
      computed: {
          currentBrand() {
            const index = this.brands.findIndex((brand) => brand.id === this.brand_id)
            if (index > -1) {
              return this.brands[index]
            } else {
              return null
            }
          },
          currentModel() {
            if (this.currentBrand === null || this.model_id === null) {
              return null
            } else {
              const index = this.currentBrand.models.findIndex((model) => model.id === this.model_id)
              return this.currentBrand.models[index]
            }
          },
          currentModels() {
              if (this.brand_id !== null) {
                  return this.currentBrand.models
              } else {
                  return []
              }
          },
          currentVersions() {
              if (this.currentModel !== null) {
                  return this.currentModel.versions
              } else {
                  return []
              }
          }
      }
  }
</script>

<style scoped>

  .brands-list, .models-list, .complectation-list {
    width: calc(100%/3 - 7px);
  }

  .mega-menu {
    display: flex;
  }

  .header-scrolled .mega-menu-arrow > path {
    fill: #000000;
  }


  .nav-container {
    position: absolute;
    top: 90px;
    left: 0;
    display: flex;
    align-items: start;
    gap: 10px;
    width: 100%;
    padding: 0 60px;
    flex: 0 0 100%;
  }

  .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px 20px 20px 30px;
      /* width: 470px; */
      background: #ffffff;
      border-top-left-radius: 10px 10px;
      border-top-right-radius: 10px 10px;
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: rgba(0, 0, 0, 0.5);
  }

  .list-wrapper {
    overflow-y:scroll;
    scrollbar-color: rgba(0, 0, 0, 0.5) #ffffff !important;
    scrollbar-width: thin !important;
    /* height: 580px; */
    height: calc(100vh - 180px);
  }

  .list-wrapper::-webkit-scrollbar {
    width: 5px;
  }

  .list-wrapper::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .list-wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }


  .brands-list {
    border-radius: 10px;
    /*max-width: 440px;
    width: calc(100% - 1310px - 20px);*/
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    user-select: none;
  }

  .models-list {
    border-radius: 10px;
    /*width: 655px;*/
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: #ffffff;
    flex-shrink: 0;
  }

  .complectation-list {
    border-radius: 10px;
    /*width: 655px;*/
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: hidden;
    flex-shrink: 0;
  }

  .brand-name {
      padding: 22px 50px 22px 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.16px;
      color: #000;
      transition: all 0.2s ease-in-out;
      background: #ffffff;
      overflow: hidden;
  }

  .brand-name:hover,.brand-name.selected {
      background: #f7f7f7;
      /* padding: 19px 30px 19px 50px; */
      padding: 22px 50px 22px 50px;
  }

  .model-name {
    padding: 0 0 0 30px;
    width: 100%;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    cursor: pointer;
  }

  .model-img {
    width: 165px;
    height: 90px;
    transform: scaleX(-1);
  }

  .model-text {
      display: flex;
      padding: 0 50px 0 30px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #000;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
  }

  .model-name:hover {
      background: #f7f7f7;
  }

  .model-name.selected {
    background: #f7f7f7;
  }

  .model-name.selected>.model-text {
    padding: 0 30px 0 50px;
  }

  .model-name-text {
      width: 100%
  }

  .model-name:hover>.model-text {
      padding: 0 30px 0 50px;
  }

  .version-wrapper {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
  }

  .version-details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 22px 30px;
  }

  .version-price {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: rgba(0, 0, 0, 0.5);
  }

  .version-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #000;
  }

  .call-btn {
    border-radius: 6px;
    border: none;
    padding: 5px 10px;
    width: 136px;
    background: #000;
    font-weight: 400;
    color: #ffffff;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    user-select: none;
  }

  .chat-btn {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #000;
    color:#ffffff;
    border-radius: 6px;
    padding: 5px 10px;
    width: 136px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    user-select: none;
    margin: 0!important;
  }
  .chat-btn:hover {
    background: #9f9f9f;
    border: 1px solid #9F9F9FFF;
    color:#ffffff;
    text-decoration: none;
  }

  .call-btn:hover {
    background: transparent;
    color:#000;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .buttons {
      align-items: center;
      gap: 10px;
      display: none;
  }

  .version-wrapper:hover {
      /* padding: 0 10px 0 10px; */
      /* background: #f7f7f7; */
  }

  .version-wrapper:hover>.buttons {
      display: flex;
  }

  @media (min-width: 2000px) {
    .models-list,.complectation-list {width: 750px;}
    .brands-list {
      /*max-width: 1440px;
      width: calc(100% - 1500px - 20px);*/
    }

  }

  @media (min-width: 1541px) {
    .version-wrapper:hover {
      /* padding: 0 30px 0 50px; */
    }
  }


  @media (max-width: 1740px) {
    /*.models-list,.complectation-list {width: 555px;}
    .brands-list {width: calc(100% - 1110px - 20px);}*/
  }

  @media (max-width: 1540px) {
    /*.brands-list {width: calc(100% - 1000px - 20px);}*/
    .model-name, .version-wrapper {padding: 0 0 0 20px;}
    .version-details {padding: 15px 20px;}
    /*.version-wrapper:hover {padding: 0 30px 0 30px;}*/
    .brand-name {padding: 22px 30px 22px 20px;}
    .brand-name:hover {padding: 22px 20px 22px 30px;}
    .model-name:hover>.model-text {
      padding: 0 30px 0 50px;
    }

  }


  @media (max-width: 1440px) {
    /*.models-list,.complectation-list {width: 440px;}
    .brands-list {width: calc(100% - 880px - 20px);}*/
    .nav-container {padding: 0 40px;}
  }

  @media (max-width: 1280px) {
    .version-wrapper {padding:0 0 0 0};
    /* .version-wrapper:hover {padding: 0 10px 0 10px!important;}
    .models-list,.complectation-list {width: 400px;} */
    .brand-name,.model-text,.version-name {
      font-size: 14px;
    }
    .model-img {
      width: 110px;
      height: 60px;
    }
    .brand-name {padding: 15px 20px 15px 20px;}
    .brand-name:hover {padding: 15px 10px 15px 30px;}
    /*.brands-list {width: calc(100% - 800px - 20px);}*/
    .chat-btn {width: 100px;}
    .model-text {padding: 0 20px 0 20px;}
    .model-name:hover>.model-text {
      padding: 0 10px 0 30px;
    }
    .buttons {
      margin-right: 20px;
    }
  }

  @media (max-width: 1000px) {
    /*.models-list,.complectation-list {width: 340px;}
    .brands-list {width: calc(100% - 680px - 20px);} */
    .nav-container {padding: 0 40px;}
    .chat-btn {
      width: 90px;
      margin-right: 0;
    }

  }

</style>