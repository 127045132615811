<template>
  <div class="content-container">
    <div class="buy-new-hover">
      <div class="hover-titles">
        <div class="hover-title" @mouseenter="switchScreen(index)" v-for="(item,index) in content">
          <div class="ht-left">
            <div class="ht-title">
              <span>{{item.title}}</span>
            </div>
            <div class="ht-description">{{item.description}}</div>
          </div>
          <div class="ht-right">
            <img :src="'/img/shevron-right.svg'">
          </div>
        </div>

      </div>
      <div class="hover-image">
        <img :src="imageUrl" alt="" />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ['content'],
  data: ()=> {
    return {
      screen: 0
    }
  },
  methods: {
    switchScreen(index) {
      if (this.screen !== index) {
        this.screen = index
      }
    }
  },
  computed: {
    imageUrl() {
      return `/img/buy_new/${this.screen}.webp?v=2`
    }
  }
}
</script>

<style scoped>

  .buy-new-hover {
    display: flex;
    gap: 180px;
  }

  .hover-titles {
    width: 586px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 40px;
  }

  .hover-titles :last-child {
    border-bottom: 1px solid transparent;
  }

  .hover-title {
    cursor: pointer;
    display: flex;
    padding-bottom: 40px;
    border-bottom: 1px solid #DBDBDB;
    width: 100%;
  }

  .ht-title, .ht-left {
    width: 100%;
  }

  .ht-title span {
    font-size: 30px;
    color: #000;
    border-bottom: 1px solid transparent;
    line-height: 39px;
  }

  .ht-title span:hover {
    border-bottom: 1px solid #000000;
  }

  .ht-description {
    color: rgba(0, 0, 0, 0.70);
    margin-top: 10px;
  }

  .ht-right {
    margin-top: 10px;
    position: relative;
    padding-right: 30px;
  }
  .ht-right img {
    position: absolute;
    top:0;
    left:0;
    transition: all 0.2s ease-in-out;
  }

  .hover-title:hover .ht-right img {
    left: 10px;
  }

  .hover-image {
    width: 100%;
    transition: all 0.5s ease-out;
  }

  .hover-image img {
    width: 100%;
    transition: all 0.5s ease-out;
  }

  @media (max-width: 1500px) {
    .buy-new-hover {
      gap: 50px;
    }

    .hover-titles {
      width: 445px;
      gap: 30px;
    }

    .hover-title {
      padding-bottom: 30px;
    }

    .ht-title span {
      font-size: 22px;
      line-height: 28px;
    }
  }

</style>